import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
     
      <Layout >
      {/* <div style={{background: 'linear-gradient(to bottom, #0F141A, 	#313B45)'}}>*/}
      {/*<div style={{background: 'linear-gradient(to bottom,  #022963, #1e3c86, #394faa, #5464cf, #7178f5)'}}>*/}
      <div style={{background: 'linear-gradient(to bottom, #f12711, #f5af19)'}}>      
    
        <Component {...props} />
         </div>
      </Layout>
     
    )}
  />
);

export default LayoutRoute;
