import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
class AuthPage extends React.Component {
    constructor (props) {
    super(props)
    this.state = {
      formNewTask:{
        username:'',
        password:'',
      },
      loading:false,
      
    }
  } 
  handleAuthState = authState => {
   // alert("ok1")
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
   //alert("ok1")
    this.props.history.push('/');
  };
  
  onSubmit = () => {
   // alert("ok1")
    this.props.history.push('/user-registration-page/');
  };
  onSubmitNew = () => {
   // alert("ok1")
    this.props.history.push('/user-password-recovery');
  };

  onActivate=()=>{
    let username=prompt("Please Enter username")
    //alert(username)
    this.props.history.push({pathname: '/user-update-first-payment-detail',state: { username:username } })
  }

  render() {
    console.log("this.props auth page")
    //console.log(this.props)
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
            />
              <br/><hr/>
              <Button color="link" onClick={()=>this.onSubmit()}>New User Registration </Button>
               <Button color="link" onClick={()=>this.onSubmitNew()}>Forget Password </Button>
               <Button color="link" onClick={()=>this.onActivate()}>Activate Account </Button>
          </Card>
        </Col>
      </Row>
       
    );
  }
componentWillReceiveProps(nextProps) {
    console.log("aut pagenext props")
    console.log(nextProps)
      this.setState({loading:false},()=>{console.log("okkkkk")})
    if(nextProps.LOGIN_CHECK.login==true){
      if(nextProps.LOGIN_CHECK.active==0) {
        //alert(JSON.stringify(nextProps))
        let userDetail={
          "NAME": nextProps.LOGIN_CHECK.NAME,
          "EMAIL": nextProps.LOGIN_CHECK.EMAIL,
          "MOBILE":  nextProps.LOGIN_CHECK.MOBILE,
        }
        //this.props.history.push({pathname: '/user-bank-detail',state: { userID:nextProps.LOGIN_CHECK.user_ID,productList:nextProps.LOGIN_CHECK.product_list,userDetail:userDetail } })
        alert("Ask Admin to Acitvate your Account")
      }
      else{
        console.log("dashboard redirect url")
        //console.log(nextProps)
        alert("Website is under development and many more features will be added later")
        if (nextProps.LOGIN_CHECK.TC_APPROVED== "0"){
          this.props.history.push({pathname: '/user-dashboard',state: { userID:nextProps.LOGIN_CHECK.user_ID } })
        }else{
          if (nextProps.LOGIN_CHECK.level=="1"){
             this.props.history.push({pathname: '/dashboard',state: { userID:nextProps.LOGIN_CHECK.user_ID } })
          }else{
             this.props.history.push({pathname: '/user-dashboard',state: { userID:nextProps.LOGIN_CHECK.user_ID } })
          }
   

        }
        
      }
        
      }else{
        if (nextProps.LOGIN_CHECK.msg=="Logout"){//alert(nextProps.LOGIN_CHECK.msg)
        } else{ alert(nextProps.LOGIN_CHECK.msg)        }
       
    }
  }
}
function mapStateToProps({ mainReducers}) {
 
  //console.log("First")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     LOGIN_CHECK: mainReducers.LOGIN_CHECK,
    
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage) ;

