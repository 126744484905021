import Page from 'components/Page';
import logo200Image from 'assets/img/logo/pashupatiLOGO.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col,Card,CardBody,CardHeader } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'

import styles from './Invoice.module.scss'

import LineItem from './LineItem'
import LineItems from './LineItems'
import { MdAddCircle as AddIcon } from 'react-icons/md'
import uuidv4 from 'uuid/v4'

class PrintReceipt extends React.Component {
  locale = 'en-IN'
  currency = 'INR'
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        user_ID:0,
      },
      taxRate: 0.00,
      userDetail:{},
      lineItems: [
        {
          name:'',
          description:"",
          price:"",
          month_emi:0,
        }
      ],
      invoiceDetail:{},
      invoiceDefault:{
        invoiceNo:"",
        name:"",
        username:"",
        date:"",
        ID:0
      },
      userDefault:{
        ID:"",
        username:"",
        display_name:"",
        usermobile:"",
        useremail:"",
        date:""
      }
    }
  } 

  handleInvoiceChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleLineItemChange = (elementIndex) => (event) => {
    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item
      return {...item, [event.target.name]: event.target.value}
    })
    this.setState({lineItems})
  }

  handleAddLineItem = (event) => {
    this.setState({
      // use optimistic uuid for drag drop; in a production app this could be a database id
      lineItems: this.state.lineItems.concat(
        [{ id: uuidv4(), name: '', description: '',  price: 0.00,month_emi:0 }]
      )
    })
  }

  handleRemoveLineItem = (elementIndex) => (event) => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i
      })
    })
  }

  handleReorderLineItems = (newLineItems) => {
    this.setState({
      lineItems: newLineItems,
    })
  }

  handleFocusSelect = (event) => {
    event.target.select()
  }

  handlePayButtonClick = () => {
    alert('Not implemented')
  }

  formatCurrency = (amount) => {
    return (new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount))
  }

  calcTaxAmount = (c) => {
    return c * (this.state.taxRate / 100)
  }

  calcLineItemsTotal = () => {
    //alert(JSON.stringify(this.state.lineItems))
    return this.state.lineItems.reduce((prev, cur) => (prev + (cur.month_emi)), 0)
  }

  calcTaxTotal = () => {
    return this.calcLineItemsTotal() * (this.state.taxRate / 100)
  }

  calcGrandTotal = () => {
    return this.calcLineItemsTotal() //+ this.calcTaxTotal()
  }

  render() {
   var converter = require('number-to-words');
   
   let invoice=(JSON.stringify(this.state.invoiceDetail)=="{}" || JSON.stringify(this.state.invoiceDetail)=="undefined")  ?this.state.invoiceDefault:this.state.invoiceDetail
   //alert(JSON.stringify(invoice))
   let user=JSON.stringify(this.state.userDetail)=="" || JSON.stringify(this.state.userDetail)==undefined ?this.state.userDefault:this.state.userDetail
   if (invoice==undefined){
     return(<></>)
   }else{
    return (
      <div className={styles.invoice}>
          <div className={styles.brand}>
            <img  src={logo200Image} alt="pashupati foundation" className={styles.logo} />
          </div>
  
          <div className={styles.addresses}>
            <div className={styles.from}>
              <strong>PASHUPATI TOURISM</strong><br />
              MAIN ROAD MODEL TOWN SURYA APARTMENT CHOWK,<br /> In Front Of Usha Printers, <br />
              P.O.NEHRU NAGAR BHILAI C.G. <br/> 
            </div>
            <div>
              <div className={`${styles.valueTable} ${styles.to}`}>
                <div className={styles.row}>
                  <div className={styles.label}>Receipt No. </div>
                  <div className={styles.value}>{invoice.ID}</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Date</div>
                  <div className={`${styles.value} ${styles.date}`}>{invoice.dateofpayment}</div>
                </div>
               <div className={styles.row}>
                  <div className={styles.label}>Bill To</div>
                  <div className={styles.value}>{user.display_name}</div>
                  <div className={styles.value}><b>({user.username})</b></div>
                </div>
              </div>
            </div>
          </div>
  
          <br/>
          <b><h5 className={styles.h2}>Advance Booking Receipt</h5></b>
            <LineItems
              items={this.state.lineItems}
              currencyFormatter={this.formatCurrency}
              addHandler={this.handleAddLineItem}
              changeHandler={this.handleLineItemChange}
              focusHandler={this.handleFocusSelect}
              deleteHandler={this.handleRemoveLineItem}
              reorderHandler={this.handleReorderLineItems}
              invoiceDetail={this.state.invoiceDetail}
            />
            
          <div className={styles.totalContainer}>
            <form>
             
            </form>
            <form>
              <div className={styles.valueTable}>
              <br />
              <br />
  
              <br />
              <br />
                <div className={styles.row}>
                 {/*  <div className={styles.label}>Total Due</div>
                  <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcLineItemsTotal())}</div>
                   */}
                </div>
  
                <div className={styles.row}>
                {/*  
                  <div className={styles.label}>Discount</div>
                  <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(0)}</div>
                  */}
                </div>
                
                <div className={styles.row}>
                  <div className={styles.label}>Amount Received</div>
                  <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcGrandTotal())}</div>
                </div>
                 <div className={styles.row}>
                 {/* 
                  <div className={styles.label}>Balance Amount</div>
                  <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(0)}</div>
                   */}
                </div>
              </div>
            </form>
          </div>
          <div className={styles.totalContainer}>
           <div className={styles.payamount}>
            <p><small>Receipt Amount In Words:</small><br/><h5> {converter.toWords(this.calcGrandTotal()).toUpperCase()}{" Only" }</h5> </p>
          </div>
          
          <div className={styles.pay}>
           <button className={styles.payNow} >Paid</button>
            
          </div>
  
          </div>
          <div className={styles.footer}>
            <div className={styles.comments}>
              <h4>Notes</h4>
              <ul>
              <li><b>Advance Booking Amount is Non Refundable at any condition</b></li>
              <li>Please Refer Link for Terms and Condition (https://pashupatitourism.com/terms-conditions/)</li>
              <li>Check out Our FAQ for queries (https://pashupatitourism.com/faqs-related-to-service/). </li>
              </ul>
              <p></p>
            </div>
            <div className={styles.closing}>
              <div>Thank-you for your business</div>
              <div><small>https://pashupatitourism.com</small></div>
            </div>
          </div>
        </div>
         
  
      );
   }
   
  }

  componentDidMount() {
    let fdata={
      user_ID:this.props.LOGIN_CHECK.user_ID, 
      emi_month:this.props.USER_EMI_RECEIPT.pay_month,
    }
    //alert(JSON.stringify(fdata))
    this.props.userInvoiceDetail("userInvoiceDetail",fdata)
    console.log("print receipt didmount")
    console.log(this.props)
  }

  componentWillReceiveProps(nextProps) {
    console.log("print receipt will")
    console.log(nextProps)
    if (nextProps.TYPE=="USER_INVOICE_DETAIL"){
      this.setState((state) => {
        return {
            userDetail:nextProps.USER_INVOICE_DETAIL.USER,
            lineItems:nextProps.USER_INVOICE_DETAIL.PRODUCT,
            invoiceDetail:nextProps.USER_INVOICE_DETAIL.EMI_CARD,
          };
        },()=>{console.log()});
      }
    }   


  //////////////end here
}

function mapStateToProps({ mainReducers}) {
  //console.log(mainReducers)
   return {
    TYPE:mainReducers.TYPE,
    LOGIN_CHECK: mainReducers.LOGIN_CHECK,
    USER_INVOICE_DETAIL:mainReducers.USER_INVOICE_DETAIL,
    USER_EMI_RECEIPT:mainReducers.USER_EMI_RECEIPT,
  }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintReceipt) ;

