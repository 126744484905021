import logo200Image from 'assets/img/logo/logo_200_black.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col,ModalHeader,Card,CardBody } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'

class UserRegistrationPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
      },
      loading:false,
      modal: false,
    }
  } 
  


  onlineSubmit = event => {
    //this.props.history.push('/user-online-registration');
    alert("Under Working")
  };

  epinSubmit = event => {
    this.props.history.push('/user-registration/');
  };

  offlineSubmit =(productID)=> {
    //alert(productID)
    this.props.history.push({pathname: '/user-offline-registration',state: { productID:productID } });
    //this.props.history.push({pathname: '/user-update-payment-detail',state: { searchUserID:data["ID"] } })
  };

  signinSubmit = event => {
    this.props.history.push('/');
  };


  render() {
    return (
      <div class="row" style={{display: "flex",justifyContent: "center",height:'130vh', color:'white', padding:10 }}>
        <div className="col-md-4"  >
        <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'  >

        <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 200, height: 200, cursor: 'pointer' }}
              alt=""
             
            /><br/>
         
          </div>

        <Button color="warning" style={{margin:10, width:400}} onClick={this.onlineSubmit}>Registration Using Online Payment </Button>
       
        <Button color="warning" style={{margin:10, width:400}} onClick={()=>this.offlineSubmit(1)} >Registeration Offiline (Only Chhattisgarh)</Button>
        <Button color="warning" style={{margin:10, width:400}} onClick={()=>this.offlineSubmit(2)} >Registeration Offiline (North-East Region)</Button>
        <Button color="warning" style={{margin:10, width:400}} onClick={this.signinSubmit} >Sign In</Button>

        </LoadingOverlay>
        </div>
      </div>
    );
  }
  componentDidMount() {
    //console.log("did mount")

  }

  componentWillReceiveProps(nextProps) {
    
  }
  //////////////end here
}

function mapStateToProps({ mainReducers}) {
   return {
     MAIN:mainReducers,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistrationPage) ;

