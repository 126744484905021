import * as ActionTypes from 'actions/ActionTypes'
const defaultState={
    TYPE:"",
    USER_REGISTRATION:{},
    USER_REGISTRATION_CHECK:{},
    LOGIN_CHECK:{},
    SIDEBAR_MENU:{},
    USER_REGISTERED_LIST_VIEW:{},
    NETWORK_TREE:{},
    ADMIN_ADD_NEW_USER:{},
    ADMIN_USER_LIST_VIEW:{},
    USER_GENERATE_PIN:{},
    ADD_TO_CART:{},
    PRODUCT_LIST:{},
    INSERT_TRANSACTION_REG:{},
    PACKAGE_LIST:{},
    PLAN_UPGRADE:{},
    USER_DETAIL:{},
    BANK_DETAIL_UPDATE:{},
    KYC_DETAIL_UPDATE:{},
    BANK_DETAIL_VIEW:{},
    USER_DETAIL_VIEW:{},
    USER_DETAIL_UPDATE:{},
    USER_ADD_MEMBER:{},
    USER_EPIN_DETAIL:{},
    USER_SUPPORT_TICKET_INSERT:{},
    USER_SUPPORT_TICKET_VIEW:{},
    USER_SUPPORT_TICKET_TIMELINE:{},
    ADMIN_EPIN_DETAIL:{},
    ADMIN_EPIN_UPDATE:{},
    ADMIN_SUPPORT_TICKET_LIST:{},
    ADMIN_ALL_USERS_LIST:{},
    ADMIN_USER_VERIFICATION:{},
    ADMIN_APPROVE_USER_VERIFICATION:{},
    INSERT_TRANSACTION_LOG:{},
    INSERT_EPIN_TRANSACTION_LOG:{},
    INSERT_EPIN_ONLINE_PAYMENT_DETAIL:{},
    INSERT_EPIN_POINT_PAYMENT_DETAIL:{},
    INSERT_EPIN_REGISTRATION_DETAIL:{},
    ADMIN_DASHBOARD:{},
    USER_DASHBOARD:{},
    REQUEST_OTP:{},
    USER_PLAN_UPGRADE:{},
    EPIN_TRANSFER_REQUEST_OTP:{},
    EPIN_TRANSFER_CONFIRM:{},
    WALLET_DETAIL:{},
    WALLET_TRANSFER_REQUEST_OTP:{},
    WALLET_TRANSACTION_DETAIL:{},
    WALLET_TRANSFER_CONFIRM:{},
    KYC_FINAL_SUBMIT:{},
    USER_REQUEST_OTP:{},
    USER_UPDATE_PASSWORD:{},
    USER_TEAM_LIST:{},
    VIEW_SEARCH_TABLE:{},
    VIEW_WALLET_WITHDRAWAL_DETAIL:{},
    UPDATE_WALLET_WITHDRAWAL:{},
    USER_NOMINEE_DETAIL:{},
    USER_NOMINEE_UPDATE_DETAIL:{},
    ADMIN_USER_LIST_FOR_RECEIPT:{},
    USER_INVOICE_DETAIL:{},
    ADMIN_CREATE_INVOICE:{},
    USER_INVOICE_LIST:{},
    ADMIN_ROYALTY_USER_LIST:{},
    ADMIN_ROYALTY_PAYOUT_DETAIL:{},
    ADMIN_ROYALTY_PAID_DETAIL:{},
    USER_UNPAID_MEMBERS:{},
    USER_ACTIVATE_MEMBER:{},
    USER_PAID_MEMBERS:{},
    USER_TREE_INSERT:{},
    USER_COMMISSION_REPORT:{},
    COMMISSION_PAY:{},
    USER_REGISTER_ACTIVATE:{},
    PAY_BONUS:{},
    PAY_DATES:{},
    USER_SILVER_COMMISSION_PAY:{},
    USER_GOLD_COMMISSION_PAY:{},
    USER_EPIN_AVAILABLE_LIST:{},
    ADMIN_WALLET_WITHDRAWAL_LIST:{},
    ADMIN_WALLET_BATCH_LIST:{},
    ADMIN_VIEW_BATCH:{},
    ADMIN_WALLET_UPDATE_BATCH_PAYMENT:{},
    RAZORPAY_CREATE_ORDER:{},
    USER_EMI_LIST:{},
    USER_EMI_DETAIL:{},
    USER_EMI_UPDATE:{},
    ADMIN_USER_EMI_APPROVE:{},
    USER_EMI_RECEIPT:{},
    ADMIN_UPDATE_USER_DETAIL:{},
    USER_INSERT_SIGNATURE:{},
    USER_VIEW_SIGNATURE:{},
    USER_UPDATE_SIGNATURE_STATUS:{},
}
export default function base(state = defaultState, action) {
  
    switch (action.type) {
      case ActionTypes.USER_UPDATE_SIGNATURE_STATUS:{
        return{
          ...state,
          TYPE:action.type,
          USER_UPDATE_SIGNATURE_STATUS:action.payload
        }
      }
      case ActionTypes.USER_VIEW_SIGNATURE:{
        return{
          ...state,
          TYPE:action.type,
          USER_VIEW_SIGNATURE:action.payload
        }
      }
      case ActionTypes.USER_INSERT_SIGNATURE:{
        return{
          ...state,
          TYPE:action.type,
          USER_INSERT_SIGNATURE:action.payload
        }
      }
      case ActionTypes.USER_INSERT_SIGNATURE:{
        return{
          ...state,
          TYPE:action.type,
          USER_INSERT_SIGNATURE:action.payload
        }
      }
      case ActionTypes.ADMIN_UPDATE_USER_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_UPDATE_USER_DETAIL:action.payload
        }
      }
      case ActionTypes.USER_EMI_RECEIPT:{
        return{
          ...state,
          TYPE:action.type,
          USER_EMI_RECEIPT:action.payload
        }
      }
      case ActionTypes.ADMIN_USER_EMI_APPROVE:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_USER_EMI_APPROVE:action.payload
        }
      }
      case ActionTypes.USER_EMI_UPDATE:{
        return{
          ...state,
          TYPE:action.type,
          USER_EMI_UPDATE:action.payload
        }
      }
      case ActionTypes.USER_EMI_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_EMI_DETAIL:action.payload
        }
      }
      case ActionTypes.USER_EMI_LIST:{
        return{
          ...state,
          TYPE:action.type,
          USER_EMI_LIST:action.payload
        }
      }
      case ActionTypes.RAZORPAY_CREATE_ORDER:{
        return{
          ...state,
          TYPE:action.type,
          RAZORPAY_CREATE_ORDER:action.payload
        }
      }
      case ActionTypes.ADMIN_WALLET_UPDATE_BATCH_PAYMENT:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_WALLET_UPDATE_BATCH_PAYMENT:action.payload
        }
      }
      case ActionTypes.ADMIN_VIEW_BATCH:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_VIEW_BATCH:action.payload
        }
      }
      case ActionTypes.ADMIN_WALLET_BATCH_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_WALLET_BATCH_LIST:action.payload
        }
      }
      case ActionTypes.ADMIN_WALLET_WITHDRAWAL_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_WALLET_WITHDRAWAL_LIST:action.payload
        }
      }
      case ActionTypes.USER_EPIN_AVAILABLE_LIST:{
        return{
          ...state,
          TYPE:action.type,
          USER_EPIN_AVAILABLE_LIST:action.payload
        }
      }
      case ActionTypes.USER_GOLD_COMMISSION_PAY:{
        return{
          ...state,
          TYPE:action.type,
          USER_GOLD_COMMISSION_PAY:action.payload
        }
      }
      case ActionTypes.USER_SILVER_COMMISSION_PAY:{
        return{
          ...state,
          TYPE:action.type,
          USER_SILVER_COMMISSION_PAY:action.payload
        }
      }
      case ActionTypes.PAY_DATES:{
        return{
          ...state,
          TYPE:action.type,
          PAY_DATES:action.payload
        }
      }
      case ActionTypes.PAY_BONUS:{
        return{
          ...state,
          TYPE:action.type,
          PAY_BONUS:action.payload
        }
      }
      case ActionTypes.USER_REGISTER_ACTIVATE:{
        return{
          ...state,
          TYPE:action.type,
          USER_REGISTER_ACTIVATE:action.payload
        }
      }
      case ActionTypes.USER_REGISTRATION_CHECK:{
        return{
          ...state,
          TYPE:action.type,
          USER_REGISTRATION_CHECK:action.payload
        }
      }
      case ActionTypes.COMMISSION_PAY:{
        return{
          ...state,
          TYPE:action.type,
          COMMISSION_PAY:action.payload
        }
      }
      case ActionTypes.USER_ACTIVATE_MEMBER:{
        return{
          ...state,
          TYPE:action.type,
          USER_ACTIVATE_MEMBER:action.payload
        }
      }
      case ActionTypes.USER_COMMISSION_REPORT:{
        return{
          ...state,
          TYPE:action.type,
          USER_COMMISSION_REPORT:action.payload
        }
      }
      case ActionTypes.USER_TREE_INSERT:{
        return{
          ...state,
          TYPE:action.type,
          USER_TREE_INSERT:action.payload
        }
      }
      case ActionTypes.USER_PAID_MEMBERS:{
        return{
          ...state,
          TYPE:action.type,
          USER_PAID_MEMBERS:action.payload
        }
      }
      case ActionTypes.USER_UNPAID_MEMBERS:{
        return{
          ...state,
          TYPE:action.type,
          USER_UNPAID_MEMBERS:action.payload
        }
      }
      case ActionTypes.ADMIN_ROYALTY_PAYOUT_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_ROYALTY_PAYOUT_DETAIL:action.payload
        }
      }
      case ActionTypes.ADMIN_ROYALTY_USER_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_ROYALTY_USER_LIST:action.payload
        }
      }
      case ActionTypes.USER_INVOICE_LIST:{
        return{
          ...state,
          TYPE:action.type,
          USER_INVOICE_LIST:action.payload
        }
      }
      case ActionTypes.ADMIN_CREATE_INVOICE:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_CREATE_INVOICE:action.payload
        }
      }
      case ActionTypes.USER_INVOICE_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_INVOICE_DETAIL:action.payload
        }
      }
      case ActionTypes.ADMIN_USER_LIST_FOR_RECEIPT:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_USER_LIST_FOR_RECEIPT:action.payload
        }
      }
      case ActionTypes.USER_NOMINEE_UPDATE_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_NOMINEE_UPDATE_DETAIL:action.payload
        }
      }
      case ActionTypes.USER_NOMINEE_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_NOMINEE_DETAIL:action.payload
        }
      }
      case ActionTypes.UPDATE_WALLET_WITHDRAWAL:{
        return{
          ...state,
          TYPE:action.type,
          UPDATE_WALLET_WITHDRAWAL:action.payload
        }
      }
      case ActionTypes.VIEW_WALLET_WITHDRAWAL_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          VIEW_WALLET_WITHDRAWAL_DETAIL:action.payload
        }
      }
      case ActionTypes. VIEW_SEARCH_TABLE:{
        return{
          ...state,
          TYPE:action.type,
          VIEW_SEARCH_TABLE:action.payload
        }
      }
      case ActionTypes.USER_TEAM_LIST:{
        return{
          ...state,
          TYPE:action.type,
          USER_TEAM_LIST:action.payload
        }
      }
      case ActionTypes.USER_UPDATE_PASSWORD:{
        return{
          ...state,
          TYPE:action.type,
         USER_UPDATE_PASSWORD:action.payload
        }
      }
      case ActionTypes.USER_REQUEST_OTP:{
        return{
          ...state,
          TYPE:action.type,
         USER_REQUEST_OTP:action.payload
        }
      }
      case ActionTypes.KYC_FINAL_SUBMIT:{
        return{
          ...state,
          TYPE:action.type,
         KYC_FINAL_SUBMIT:action.payload
        }
      }
      case ActionTypes.WALLET_TRANSFER_CONFIRM:{
        return{
          ...state,
          TYPE:action.type,
         WALLET_TRANSFER_CONFIRM:action.payload
        }
      }
      case ActionTypes.WALLET_TRANSACTION_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          WALLET_TRANSACTION_DETAIL:action.payload
        }
      }
      case ActionTypes.WALLET_TRANSFER_REQUEST_OTP:{
        return{
          ...state,
          TYPE:action.type,
          WALLET_TRANSFER_REQUEST_OTP:action.payload
        }
      }
      case ActionTypes.WALLET_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          WALLET_DETAIL:action.payload
        }
      }
      case ActionTypes.EPIN_TRANSFER_CONFIRM:{
        return{
          ...state,
          TYPE:action.type,
          EPIN_TRANSFER_CONFIRM:action.payload
        }
      }
      case ActionTypes.EPIN_TRANSFER_REQUEST_OTP:{
        return{
          ...state,
          TYPE:action.type,
          EPIN_TRANSFER_REQUEST_OTP:action.payload
        }
      }
      case ActionTypes.USER_PLAN_UPGRADE:{
        return{
          ...state,
          TYPE:action.type,
          USER_PLAN_UPGRADE:action.payload
        }
      }
      case ActionTypes.REQUEST_OTP:{
        return{
          ...state,
          TYPE:action.type,
          REQUEST_OTP:action.payload
        }
      }
      case ActionTypes.USER_DASHBOARD:{
        return{
          ...state,
          TYPE:action.type,
          USER_DASHBOARD:action.payload
        }
      }
      case ActionTypes.ADMIN_DASHBOARD:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_DASHBOARD:action.payload
        }
      }
      case ActionTypes.INSERT_EPIN_REGISTRATION_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_EPIN_REGISTRATION_DETAIL:action.payload
        }
      }
      case ActionTypes.INSERT_EPIN_POINT_PAYMENT_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_EPIN_POINT_PAYMENT_DETAIL:action.payload
        }
      }
      case ActionTypes.INSERT_EPIN_ONLINE_PAYMENT_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_EPIN_ONLINE_PAYMENT_DETAIL:action.payload
        }
      }
      case ActionTypes.INSERT_EPIN_ONLINE_PAYMENT_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_EPIN_ONLINE_PAYMENT_DETAIL:action.payload
        }
      }
      case ActionTypes.INSERT_EPIN_TRANSACTION_LOG:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_EPIN_TRANSACTION_LOG:action.payload
        }
      }
      case ActionTypes.INSERT_TRANSACTION_LOG:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_TRANSACTION_LOG:action.payload
        }
      }
      case ActionTypes.ADMIN_APPROVE_USER_VERIFICATION:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_APPROVE_USER_VERIFICATION:action.payload
        }
      }
      case ActionTypes.ADMIN_USER_VERIFICATION:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_USER_VERIFICATION:action.payload
        }
      }
      case ActionTypes.ADMIN_ALL_USERS_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_ALL_USERS_LIST:action.payload
        }
      }
      case ActionTypes.ADMIN_SUPPORT_TICKET_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_SUPPORT_TICKET_LIST:action.payload
        }
      }
      case ActionTypes.ADMIN_EPIN_UPDATE:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_EPIN_UPDATE:action.payload
        }
      }
      case ActionTypes.ADMIN_EPIN_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_EPIN_DETAIL:action.payload
        }
      }
      case ActionTypes.USER_SUPPORT_TICKET_TIMELINE:{
        return{
          ...state,
          TYPE:action.type,
          USER_SUPPORT_TICKET_TIMELINE:action.payload
        }
      }
      case ActionTypes.USER_SUPPORT_TICKET_VIEW:{
        return{
          ...state,
          TYPE:action.type,
          USER_SUPPORT_TICKET_VIEW:action.payload
        }
      }
      case ActionTypes.USER_SUPPORT_TICKET_INSERT:{
        return{
          ...state,
          TYPE:action.type,
          USER_SUPPORT_TICKET_INSERT:action.payload
        }
      }
      case ActionTypes.USER_EPIN_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_EPIN_DETAIL:action.payload
        }
      }
      case ActionTypes.USER_EPIN_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_EPIN_DETAIL:action.payload
        }
      }
      case ActionTypes.USER_ADD_MEMBER:{
        return{
          ...state,
          TYPE:action.type,
          USER_ADD_MEMBER:action.payload
        }
      }
      case ActionTypes.USER_DETAIL_UPDATE:{
        return{
          ...state,
          TYPE:action.type,
          USER_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.USER_DETAIL_VIEW:{
        return{
          ...state,
          TYPE:action.type,
          USER_DETAIL_VIEW:action.payload
        }
      }
      case ActionTypes.BANK_DETAIL_VIEW:{
        return{
          ...state,
          TYPE:action.type,
          BANK_DETAIL_VIEW:action.payload
        }
      }
      case ActionTypes.KYC_DETAIL_UPDATE:{
        return{
          ...state,
          TYPE:action.type,
          KYC_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.BANK_DETAIL_UPDATE:{
        return{
          ...state,
          TYPE:action.type,
          BANK_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.USER_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          USER_DETAIL:action.payload
        }
      }
      case ActionTypes.PLAN_UPGRADE:{
        return{
          ...state,
          TYPE:action.type,
          PLAN_UPGRADE:action.payload
        }
      }
      case ActionTypes.LOGOUT_CHECK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            LOGIN_CHECK:{
              'login':false,
              'msg':'Logout',
              'user_ID':0
            }
        }
      }
      case ActionTypes.PACKAGE_LIST:{
        return{
          ...state,
          TYPE:action.type,
          PACKAGE_LIST:action.payload
        }
      }
      case ActionTypes.INSERT_TRANSACTION_REG:{
        return{
          ...state,
          TYPE:action.type,
          INSERT_TRANSACTION_REG:action.payload
        }
      }
      case ActionTypes.PRODUCT_LIST:{
        return{
          ...state,
          TYPE:action.type,
          PRODUCT_LIST:action.payload
        }
      }

      case ActionTypes.ADD_TO_CART:{
        return{
          ...state,
          TYPE:action.type,
          ADD_TO_CART:action.payload
        }
      }




      case ActionTypes.USER_GENERATE_PIN:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          USER_GENERATE_PIN:action.payload
        }
      }
      case ActionTypes.ADMIN_USER_LIST_VIEW:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          ADMIN_USER_LIST_VIEW:action.payload
        }
      }
      case ActionTypes.ADMIN_ADD_NEW_USER:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          ADMIN_ADD_NEW_USER:action.payload
        }
      }
      case ActionTypes.NETWORK_TREE:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          NETWORK_TREE:action.payload
        }
      }
      case ActionTypes.USER_REGISTERED_LIST_VIEW:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          USER_REGISTERED_LIST_VIEW:action.payload
        }
      }  
      case ActionTypes.USER_REGISTRATION:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          USER_REGISTRATION:action.payload
        }
      }  
      case ActionTypes.LOGIN_CHECK:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          LOGIN_CHECK:action.payload
        }
      } 
      case ActionTypes.SIDEBAR_MENU:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          SIDEBAR_MENU:action.payload
        }
      }         
       
      default:
          return state
    }
}
