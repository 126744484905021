import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import UserRegistration from 'pages/UserRegistration/UserRegistration';
import UserOnlineRegistration from 'pages/UserOnlineRegistration/UserOnlineRegistration';
import UserOfflineRegistration from 'pages/UserOfflineRegistration/UserOfflineRegistration';

import UserRegistrationPayment from 'pages/UserRegistrationPayment/UserRegistrationPayment';
import UserPasswordRecovery from 'pages/UserPasswordRecovery/UserPasswordRecovery';
import UserUpdateFirstPaymentDetail from 'pages/UserUpdateFirstPaymentDetail/UserUpdateFirstPaymentDetail';
import Signature from 'pages/Signature/Signature';

import UserOTPRequest from 'pages/UserOTPRequest/UserOTPRequest';
import UserPasswordReset from 'pages/UserPasswordReset/UserPasswordReset';
import PrintReceipt from 'pages/PrintReceipt/PrintReceipt';
import UserRegistrationPage from 'pages/UserRegistrationPage/UserRegistrationPage'
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const UserPasswordUpdate= React.lazy(() => import('pages/UserPasswordUpdate/UserPasswordUpdate'));

const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const UserRegisteredListView = React.lazy(() => import('pages/UserRegisteredListView/UserRegisteredListView'));
const NetworkTree= React.lazy(() => import('pages/NetworkTree/NetworkTree'));
const AdminUserAddNew= React.lazy(() => import('pages/AdminUserAddNew/AdminUserAddNew'));
const AdminUserListView= React.lazy(() => import('pages/AdminUserListView/AdminUserListView'));
const AdminUserPendingList= React.lazy(() => import('pages/AdminUserPendingList/AdminUserPendingList'));
const UserGenerateEPin= React.lazy(() => import('pages/UserGenerateEPin/UserGenerateEPin'));
const UserGenerateEPinView= React.lazy(() => import('pages/UserGenerateEPinView/UserGenerateEPinView'));
const UserAccountRegistrationPayment= React.lazy(() => import('pages/UserAccountRegistrationPayment/UserAccountRegistrationPayment'));
const UserAccountReferPayment= React.lazy(() => import('pages/UserAccountReferPayment/UserAccountReferPayment'));
const UserDashboard = React.lazy(() => import('pages/UserDashboard/UserDashboard'));
const UserPlanUpgrade = React.lazy(() => import('pages/UserPlanUpgrade/UserPlanUpgrade'));
const UserBankDetail = React.lazy(() => import('pages/UserBankDetail/UserBankDetail'));
const UserKYCDetail = React.lazy(() => import('pages/UserKYCDetail/UserKYCDetail'));
const UserPersonalDetail = React.lazy(() => import('pages/UserPersonalDetail/UserPersonalDetail'));
const UserEditPersonalDetail = React.lazy(() => import('pages/UserEditPersonalDetail/UserEditPersonalDetail'));
const UserUpdatePaymentDetail = React.lazy(() => import('pages/UserUpdatePaymentDetail/UserUpdatePaymentDetail'));
const UserUpdateNewPaymentDetail = React.lazy(() => import('pages/UserUpdateNewPaymentDetail/UserUpdateNewPaymentDetail'));


const UserAddNewMember = React.lazy(() => import('pages/UserAddNewMember/UserAddNewMember'));
const UserGenerateEPinDetailView = React.lazy(() => import('pages/UserGenerateEPinDetailView/UserGenerateEPinDetailView'));
const UserSupportTicketNew = React.lazy(() => import('pages/UserSupportTicketNew/UserSupportTicketNew'));
const UserSupportTicketView = React.lazy(() => import('pages/UserSupportTicketView/UserSupportTicketView'));
const AdminEPinSetting = React.lazy(() => import('pages/AdminEPinSetting/AdminEPinSetting'));
const AdminSupportTicketList = React.lazy(() => import('pages/AdminSupportTicketList/AdminSupportTicketList'));
const AdminAllUsersList = React.lazy(() => import('pages/AdminAllUsersList/AdminAllUsersList'));
const AdminUserVerificationList = React.lazy(() => import('pages/AdminUserVerificationList/AdminUserVerificationList'));
const AdminUserVerificationView = React.lazy(() => import('pages/AdminUserVerificationView/AdminUserVerificationView'));
const AdminUsersBlockList = React.lazy(() => import('pages/AdminUserBlockList/AdminUserBlockList'));
const UserWallet = React.lazy(() => import('pages/UserWallet/UserWallet'));
const UserMyTeam = React.lazy(() => import('pages/UserMyTeam/UserMyTeam'));
const UserMyClubTeam = React.lazy(() => import('pages/UserMyClubTeam/UserMyClubTeam'));
const UserWalletPoints=React.lazy(() => import('pages/UserWalletPoints/UserWalletPoints'));
const UserWalletWithdrawal = React.lazy(() => import('pages/UserWalletWithdrawal/UserWalletWithdrawal'));
const AdminWalletWithdrawalRequestView = React.lazy(() => import('pages/AdminWalletWithdrawalRequestView/AdminWalletWithdrawalRequestView'));
const AdminWalletWithdrawalRequestDetail = React.lazy(() => import('pages/AdminWalletWithdrawalRequestDetail/AdminWalletWithdrawalRequestDetail'));
const UserNomineeDetail = React.lazy(() => import('pages/UserNomineeDetail/UserNomineeDetail'));
const UserWalletPointsExpense = React.lazy(() => import('pages/UserWalletPointsExpense/UserWalletPointsExpense'));
const AdminPrintReport = React.lazy(() => import('pages/AdminPrintReport/AdminPrintReport'));
const AdminInvoiceIssue = React.lazy(() => import('pages/AdminInvoiceIssue/AdminInvoiceIssue'));
const AdminInvoicePrint = React.lazy(() => import('pages/AdminInvoicePrint/AdminInvoicePrint'));
const UserInvoiceView = React.lazy(() => import('pages/UserInvoiceView/UserInvoiceView'));
const AdminRoyaltyUserList = React.lazy(() => import('pages/AdminRoyaltyUserList/AdminRoyaltyUserList'));
const AdminRoyaltyPayout = React.lazy(() => import('pages/AdminRoyaltyPayout/AdminRoyaltyPayout'));
////////////////////////////////////////////////////////////////////
const UserDirectTeamList = React.lazy(() => import('pages/UserDirectTeamList/UserDirectTeamList'));
const UserUnPaidMembers = React.lazy(() => import('pages/UserUnPaidMembers/UserUnPaidMembers'));
const UserPaidMembers = React.lazy(() => import('pages/UserPaidMembers/UserPaidMembers'));
const UserNetworkTree = React.lazy(() => import('pages/UserNetworkTree/UserNetworkTree'));
const UserCommissionReport = React.lazy(() => import('pages/UserCommissionReport/UserCommissionReport'));
const UserBonusReport = React.lazy(() => import('pages/UserBonusReport/UserBonusReport'));
const UserBronzeCommissionReport = React.lazy(() => import('pages/UserBronzeCommissionReport/UserBronzeCommissionReport'));
const UserSilverCommissionReport = React.lazy(() => import('pages/UserSilverCommissionReport/UserSilverCommissionReport'));
const UserGoldCommissionReport = React.lazy(() => import('pages/UserGoldCommissionReport/UserGoldCommissionReport'));
const UserEPinTransferView = React.lazy(() => import('pages/UserEPinTransferView/UserEPinTransferView'));
const AdminWalletWithdrawalApprovedRequestView = React.lazy(() => import('pages/AdminWalletWithdrawalApprovedRequestView/AdminWalletWithdrawalApprovedRequestView'));
const AdminWalletWithdrawalUpdatePaid = React.lazy(() => import('pages/AdminWalletWithdrawalUpdatePaid/AdminWalletWithdrawalUpdatePaid'));
const AdminWalletWithdrawalPaidView = React.lazy(() => import('pages/AdminWalletWithdrawalPaidView/AdminWalletWithdrawalPaidView'));
const UserMobileDashboard = React.lazy(() => import('pages/UserDashboard/UserMobileDashboard'));

const AdminUserPaymentPendingList = React.lazy(() => import('pages/AdminUserPaymentPendingList/AdminUserPaymentPendingList'));
const UserUpdatePendingPaymentDetail = React.lazy(() => import('pages/UserUpdatePendingPaymentDetail/UserUpdatePendingPaymentDetail'));
const UserEmiList = React.lazy(() => import('pages/UserEmiList/UserEmiList'));
const AdminUserPendingEmiList = React.lazy(() => import('pages/AdminUserPendingEmiList/AdminUserPendingEmiList'));
const UserInvoicePrint = React.lazy(() => import('pages/UserInvoicePrint/UserInvoicePrint'));
const AdminAllUserWalletList = React.lazy(() => import('pages/AdminAllUserWalletList/AdminAllUserWalletList'));
const Rules = React.lazy(() => import('pages/Rules/Rules'));
const BankDetail = React.lazy(() => import('pages/BankDetail/BankDetail'));




const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter >
        <GAListener>
          <Switch>
          {
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
          }
          { 
            <LayoutRoute
              exact
              path="/user-registration"
              layout={EmptyLayout}
              component={props => (
                <UserRegistration {...props} authState={STATE_LOGIN} />
              )}
            />
            }
            { 
            <LayoutRoute
              exact
              path="/user-online-registration"
              layout={EmptyLayout}
              component={props => (
                <UserOnlineRegistration {...props} authState={STATE_LOGIN} />
              )}
            />
            }
            
            {
            <LayoutRoute
              exact
              path="/user-offline-registration"
              layout={EmptyLayout}
              component={props => (
                <UserOfflineRegistration {...props} authState={STATE_LOGIN} />
              )}
            />
            }


             { 
              <LayoutRoute
                exact
                path="/user-password-recovery"
                layout={EmptyLayout}
                component={props => (
                  <UserPasswordRecovery {...props} authState={STATE_LOGIN} />
                )}
              />
            }
             { 
              <LayoutRoute
                exact
                path="/user-update-first-payment-detail"
                layout={EmptyLayout}
                component={props => (
                  <UserUpdateFirstPaymentDetail {...props} authState={STATE_LOGIN} />
                )}
              />
            }
            
            {
              <LayoutRoute
                exact
                path="/user-registration-payment"
                layout={EmptyLayout}
                component={props => (
                  <UserRegistrationPayment {...props} authState={STATE_LOGIN} />
                )}
              />
            }

{
              <LayoutRoute
                exact
                path="/user-registration-page"
                layout={EmptyLayout}
                component={props => (
                  <UserRegistrationPage {...props} authState={STATE_LOGIN} />
                )}
              />
            }
            { 
              <LayoutRoute exact  path="/user-otp-request" layout={EmptyLayout} component={props => (
                  <UserOTPRequest  {...props} authState={STATE_LOGIN} />
                )}
              />
            }
            { 
            <LayoutRoute
              exact
              path="/user-password-reset"
              layout={EmptyLayout}
              component={props => (
                <UserPasswordReset  {...props} authState={STATE_LOGIN} />
              )}
            />
            }
            { 
            <LayoutRoute
              exact
              path="/print-receipt"
              layout={EmptyLayout}
              component={props => (
                <PrintReceipt  {...props}  />
              )}
            />
            }
{ 
            <LayoutRoute
              exact
              path="/signature"
              layout={EmptyLayout}
              component={props => (
                <Signature  {...props} authState={STATE_LOGIN} />
              )}
            />
            }




            <MainLayout breakpoint={this.props.breakpoint} >
              <React.Suspense fallback={<PageSpinner />}>
                {/*<Route exact path="/" component={DashboardPage} />*/}
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/user-registered-list-view" component={UserRegisteredListView} />
                <Route exact path="/network-tree" component={NetworkTree} />
                <Route exact path="/admin-add-user" component={AdminUserAddNew} />
                <Route exact path="/user-list" component={AdminUserListView} />
                <Route exact path="/user-pending-list" component={AdminUserPendingList} />

                <Route exact path="/user-generate-pin" component={UserGenerateEPin} />
                <Route exact path="/user-generate-pin-view" component={UserGenerateEPinView} />
                <Route exact path="/user-account-registration-payment" component={UserAccountRegistrationPayment} />
                <Route exact path="/user-account-refer-payment" component={UserAccountReferPayment} />
                <Route exact path="/user-dashboard" component={UserDashboard} />
                <Route exact path="/user-mobile-dashboard" component={UserMobileDashboard} />
                
                <Route exact path="/user-password-change" component={UserPasswordUpdate} />
                <Route exact path="/user-plan-upgrade" component={UserPlanUpgrade} />
                <Route exact path="/user-bank-detail" component={UserBankDetail} />
                <Route exact path="/user-kyc-detail" component={UserKYCDetail} />
                <Route exact path="/user-personal-detail" component={UserPersonalDetail} />
                <Route exact path="/user-edit-personal-detail" component={UserEditPersonalDetail} />
                <Route exact path="/user-update-payment-detail" component={UserUpdatePaymentDetail} />
                <Route exact path="/user-update-new-payment-detail" component={UserUpdateNewPaymentDetail} />
                
                <Route exact path="/user-add-new-member" component={UserAddNewMember} />
                <Route exact path="/user-generate-pin-detail-view" component={UserGenerateEPinDetailView} />
                <Route exact path="/user-support-ticket-new" component={UserSupportTicketNew} />
                <Route exact path="/user-support-ticket-view" component={UserSupportTicketView} />
                <Route exact path="/epin-setting" component={AdminEPinSetting} />
                <Route exact path="/admin-support-ticket-list" component={AdminSupportTicketList} />
                <Route exact path="/admin-all-users-list" component={AdminAllUsersList}/>
                <Route exact path="/admin-user-verification-list" component={AdminUserVerificationList}/>
                <Route exact path="/admin-user-verification-view" component={AdminUserVerificationView}/>
                <Route exact path="/admin-user-list" component={AdminUsersBlockList}/>
                <Route exact path="/user-wallet" component={UserWallet}/>
                <Route exact path="/user-my-team" component={UserMyTeam}/>
                <Route exact path="/user-my-club-team" component={UserMyClubTeam}/>
                <Route exact path="/user-wallet-points" component={UserWalletPoints}/>
                <Route exact path="/user-wallet-withdrawal" component={UserWalletWithdrawal}/>
                <Route exact path="/admin-wallet-withdrawal-request-view" component={AdminWalletWithdrawalRequestView}/>
                <Route exact path="/admin-wallet-withdrawal-request-detail" component={AdminWalletWithdrawalRequestDetail}/>
                <Route exact path="/user-nominee-detail" component={UserNomineeDetail}/>
                <Route exact path="/user-wallet-points-expense" component={UserWalletPointsExpense}/>
                <Route exact path="/admin-print-report" component={AdminPrintReport}/>
                <Route exact path="/admin-invoice-issue" component={AdminInvoiceIssue}/>
                <Route exact path="/admin-invoice-print" component={AdminInvoicePrint}/>
                <Route exact path="/user-invoice-view" component={UserInvoiceView}/>
                <Route exact path="/admin-royalty-user-list" component={AdminRoyaltyUserList}/>
                <Route exact path="/admin-royalty-payout" component={AdminRoyaltyPayout}/>
                <Route exact path="/user-direct-team-list" component={UserDirectTeamList}/>
                <Route exact path="/user-unpaid-members" component={UserUnPaidMembers}/>
                <Route exact path="/user-paid-members" component={UserPaidMembers}/>
                <Route exact path="/user-network-tree" component={UserNetworkTree}/>
                <Route exact path="/commission-report" component={UserCommissionReport}/>
                <Route exact path="/bronze-commission-report" component={UserBronzeCommissionReport}/>
                <Route exact path="/bonus-report" component={UserBonusReport}/>
                <Route exact path="/silver-commission-report" component={UserSilverCommissionReport}/>
                <Route exact path="/gold-commission-report" component={UserGoldCommissionReport}/>
                <Route exact path="/user-epin-transfer-view" component={UserEPinTransferView}/>
                <Route exact path="/admin-wallet-withdrawal-approved" component={AdminWalletWithdrawalApprovedRequestView}/>
                <Route exact path="/admin-wallet-withdrawal-update" component={AdminWalletWithdrawalUpdatePaid}/>
                <Route exact path="/admin-wallet-withdrawal-paid-view" component={AdminWalletWithdrawalPaidView}/>
                <Route exact path="/admin-user-payment-pending-list" component={AdminUserPaymentPendingList}/>
                <Route exact path="/user-update-pending-payment-detail" component={UserUpdatePendingPaymentDetail}/>
                <Route exact path="/user-emi-list" component={UserEmiList}/>
                <Route exact path="/admin-user-pending-emi-list" component={AdminUserPendingEmiList}/>               
                <Route exact path="/user-invoice-print" component={UserInvoicePrint}/>
                <Route exact path="/admin-all-user-wallet-list" component={AdminAllUserWalletList}/>
                <Route exact path="/rules" component={Rules}/>
                <Route exact path="/bank-detail" component={BankDetail}/>
         
                
                


                <Route exact path="/login-modal" component={AuthModalPage} />
                <Route exact path="/buttons" component={ButtonPage} />
                <Route exact path="/cards" component={CardPage} />
                <Route exact path="/widgets" component={WidgetPage} />
                <Route exact path="/typography" component={TypographyPage} />
                <Route exact path="/alerts" component={AlertPage} />
                <Route exact path="/tables" component={TablePage} />
                <Route exact path="/badges" component={BadgePage} />
                <Route exact path="/button-groups" component={ButtonGroupPage} />
                <Route exact path="/dropdowns" component={DropdownPage} />
                <Route exact path="/progress" component={ProgressPage} />
                <Route exact path="/modals" component={ModalPage} />
                <Route exact path="/forms" component={FormPage} />
                <Route exact path="/input-groups" component={InputGroupPage} />
                <Route exact path="/charts" component={ChartPage} />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);

