import logo200Image from 'assets/img/logo/logo_200_white.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col,Card,CardBody } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
class UserUpdateFirstPaymentDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        user_ID:this.props.location.state.searchUserID,
        username:"",
        useremail:"",
        usermobile:"",
        display_name:"",
        refer_by:"",
        paymode:"",
        paymodedetail:"",
        dateofpayment:"",
        amount:"",
        remark:"",
      },
     
      loading:false,      
    }
  } 

  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    //console.log(this.state.formNewInput)
    //console.log("value"+value)
  }

  onSubmit=()=>{
    alert(JSON.stringify(this.state.formNewInput))
    this.props.userDetailUpdate("userUpdateFirstPayment",this.state.formNewInput)
    this.setState({loading:true},()=>{console.log("okkkkk")})
}
  onSubmitBack = () => {
   // alert("ok1")
    this.props.history.push('/');
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'100vh', color:'black' }}>
            <div className="col-md-6"  >
    
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading your content...'
              >
          <Card style={{border: "none" }}>
          <CardBody>
            <Row>
              <Col sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="username">User Name</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="username"
                      id="username"
                      value={this.state.formNewInput.username} 
                      disabled={true}   
                      onChange={this.formNewInputChange}         
                    />
                </FormGroup>
              </Col>
              <Col sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="refer_by">Refer By</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="refer_by"
                      id="refer_by"
                      value={this.state.formNewInput.refer_by} 
                      disabled={true}   
                      onChange={this.formNewInputChange}         
                    />
                </FormGroup>
              </Col>
              <Col sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="display_name">Name</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="display_name"
                      id="display_name"
                      disabled={true}   
                      value={this.state.formNewInput.display_name} 
                      placeholder="Enter the Name"
                      onChange={this.formNewInputChange}  
                     
                    />
                </FormGroup>
              </Col>
              <Col  sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="useremail">Amount</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="amount"
                      id="amount"
                      disabled={true}   
                      value={this.state.formNewInput.amount} 
                      //placeholder="Enter the Email"
                      ///onChange={this.formNewInputChange}         
                    />
                </FormGroup>
              </Col>
              <Col  sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="">Pay Mode</Label>
                    <Input type="select" name="paymode" id="paymode" onChange={this.formNewInputChange} value={this.state.formNewInput.paymode} >
                    <option value=""></option>
                    <option value="1">NEFT/RTGS</option>
                    <option value="2">Cheque</option>
                    <option value="3">Other</option>
                    </Input>
                </FormGroup>
              </Col>
              <Col  sm={12} md={6} xs={12}>
              <FormGroup>
                  <Label for="useremail">Pay Mode Detail</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="paymodedetail"
                      id="paymodedetail"
                      value={this.state.formNewInput.paymodedetail} 
                      //placeholder="Enter the Email"
                      onChange={this.formNewInputChange}         
                    />
                </FormGroup>
              </Col>
              <Col  sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="useremail">Date of Payment</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      name="dateofpayment"
                      id="dateofpayment"
                      value={this.state.formNewInput.dateofpayment} 
                      //placeholder="Enter the Email"
                      onChange={this.formNewInputChange}         
                    />
                </FormGroup>
              </Col>
              <Col  sm={12} md={6} xs={12}>
                <FormGroup>
                  <Label for="address">remark</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="remark"
                      id="remark"
                      value={this.state.formNewInput.remark} 
                      //placeholder="Enter the Address"
                      onChange={this.formNewInputChange}         
                    />
                </FormGroup>
              </Col>
                  </Row>
                   <Button size="sm" color="primary" onClick={()=>this.onSubmit()}>Confirm Detail </Button> 
                   <Button style={{margin:10}}size="sm" color="primary" onClick={()=>this.onSubmitBack()}>Back </Button> 
                </CardBody>
              </Card>
              <Card style={{border: "none" }}>
                <CardBody>
                </CardBody>
              </Card>
        </LoadingOverlay>

        </div>
     
      </div>
    );
  }

  componentDidMount() {
    console.log("did mount")
    //alert(this.props.location.state.username)
    let data={
      'username':this.props.location.state.username,
    }
    //alert(JSON.stringify(this.props.location.state.searchUserID))
    this.props.userDetailView("userDetailByUsername",data)
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if (nextProps.TYPE==='USER_DETAIL_VIEW'){ 
      if (nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL==false){
        alert("Username not matched or Account is active")
        this.props.history.push({pathname: '/' })
      }else{
        this.setState((state) => {
          return {
              formNewInput:{
                ID:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.ID,
                username:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.username,
                usermobile:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.usermobile,
                display_name:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.display_name,
                refer_by:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.refer_by,
                user_ID:this.props.location.state.searchUserID,
                paymode:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.paymode,
                paymodedetail:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.paymodedetail,
                dateofpayment:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.dateofpayment,
                amount:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.amount,
                remark:nextProps.USER_DETAIL_VIEW.PERSONAL_DETAIL.remark,
              },
          }   
        },()=>{})
      }
      
    }
    else if (nextProps.TYPE==='USER_DETAIL_UPDATE'){
      console.log("USER_DETAIL_UPDATE")
      if (nextProps.USER_DETAIL_VIEW.PERSONAL_DETAI.STATUS=="SUCCESS"){
        alert("Record Updated, Request Admin to Activate Account")
        this.props.history.push({pathname: '/' })
      }
      else{
        alert("No Record Updated, Contact Admin for Support")
        this.props.history.push({pathname: '/' })
      }

    this.setState({loading:false},()=>{console.log("okkkkk")})
  }
  //////////////end here
  }
}
export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserUpdateFirstPaymentDetail.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserUpdateFirstPaymentDetail.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducers")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_DETAIL_VIEW:mainReducers.USER_DETAIL_VIEW,
     USER_DETAIL_UPDATE:mainReducers.USER_DETAIL_UPDATE,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUpdateFirstPaymentDetail);

