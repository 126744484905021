import Page from 'components/Page';
import React from 'react';
import {
  Row,
  Button,
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Table,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownItem, 
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import {
  MdBubbleChart,
  MdFilterList,
  MdFilterFrames,
  MdDetails,
} from 'react-icons/md';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LoadingOverlay from 'react-loading-overlay'
class PaymentForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      form:{schema:{},formData:{},uischema:{}},
      onlinePaymentDetail:{
        amount:"",
        name:"",
        price:'',
        user_ID:'',
        email:'',
        mobile:'',
        TID:0,
        flag:0,
      },
      formNewInput:{
        epin:'',
        flag:0,
      },
      iAgree:false,
      eventFieldID:"",
      loading:false,  
      paymentStatus:"NO",    
    }
  }

  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
  }

  onSubmit=(e)=>{
    //{"amount":"500","name":"aaaa","price":"","user_ID":"","email":"aaaa","mobile":"aaaa"}
      let amount=e.amount*100
      let userID=this.props.userDetail.user_ID
      let cDate=new Intl.DateTimeFormat('fr-ca',{year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.now())
      let options = {
        "key": "rzp_test_rWnioL1XpzeoHL",
        "amount": amount, // 2000 paise = INR 20, amount in paisa
        "name": "MY DESTINY",
        "description": "Registration Fee",
        "image": "/your_logo.png",
        "handler":(response)=>{
          console.log("11111")
          //alert(JSON.stringify(response));
          let data={
            user_ID:userID,
            amount:amount/100,
            date_of_payment:cDate,
            razorpay_payment_ID:response.razorpay_payment_id,
            payment_type_detail:4,
            payment_remark:"",
            payment_type:"RAZORPAY",
            referby:this.props.userDetail.REFERBY,
            refer_ID:this.props.userDetail.REFER_ID,
            name:this.props.userDetail.NAME,
            TID:this.state.onlinePaymentDetail.TID
          }
         
          this.props.insertRegistrationFeeDetail('insertRegistrationFeeDetail',data)
           this.setState({loading:true},()=>{console.log("okkkkk")})
          //this.onCheck(data)
        },
        "prefill": {
          "name": e.name,
          "email": e.email ,
          "contact":e.mobile
        },
        "notes": {
          "address": "Thankyou"
        
        },
        "theme": {
          "color": "#F37254"
        }
      };
      let rzp =new window.Razorpay(options)
      rzp.open();
     // alert(JSON.stringify(rzp)+":   ok")

  }

  onTransactionLog=(e)=>{
    //{"amount":"1000","name":"manoj","price":"","user_ID":"","email":"manoj@gmail.com","mobile":"123"}
    let fdata={
      payment_type:"Online Payment",
      user_ID:this.props.userDetail.user_ID,
      amount:this.state.onlinePaymentDetail.amount,
      transaction_log_type:1
    }
    //alert(JSON.stringify(fdata))
     this.state.onlinePaymentDetail.flag=0
     this.state.formNewInput.flat=0
    this.props.insertTransactionLog("insertTransactionLog",fdata)
     this.setState({loading:true},()=>{console.log("okkkkk")})
  }
  
  onSubmitEpin=(e)=>{ 
    //{"amount":"500","name":"dfdfdf","price":"","user_ID":"","email":"trust@gmail.com","mobile":"9643490934"}
    //alert("epin"+JSON.stringify(e))
    if (this.state.iAgree==true){
      let cDate=new Intl.DateTimeFormat('fr-ca',{year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.now())
      let amount=e.amount
      let fdata={
        payment_type:"EPIN",
        user_ID:this.props.userDetail.user_ID,
        amount:amount,
        transaction_log_type:2,
        epin:this.state.formNewInput.epin
      }
      //alert("epin"+JSON.stringify(fdata))
      this.props.insertTransactionLog("insertTransactionLog",fdata)
      this.setState({loading:true},()=>{console.log("okkkkk")})
    }
    else{
      alert("Please Check the Terms and Condition Box")
    }
  }
  
  onAgree=()=>{
    this.setState({iAgree:!this.state.iAgree},()=>{})
  }

  render() {
    console.log(this.props)
    if (this.props.INSERT_TRANSACTION_LOG.STATUS==="SUCCESS" && this.props.INSERT_TRANSACTION_LOG.PAYMENT_METHOD=== "Online Payment" && this.state.onlinePaymentDetail.flag==0){
      //this.submit()
      //alert(JSON.stringify(this.props.INSERT_TRANSACTION_LOG))
      this.state.onlinePaymentDetail.TID=this.props.INSERT_TRANSACTION_LOG.ID
      this.state.onlinePaymentDetail.flag=1
      this.onSubmit(this.state.onlinePaymentDetail)
       this.setState({loading:true},()=>{console.log("okkkkk")})
    }else if (this.props.INSERT_TRANSACTION_LOG.STATUS==="SUCCESS" && this.props.INSERT_TRANSACTION_LOG.PAYMENT_METHOD=== "EPIN" && this.state.formNewInput.flag==0 ){
     
      let fdata={
        payment_type:"EPIN",
        user_ID:this.props.userDetail.user_ID,
        amount:this.state.onlinePaymentDetail.amount,
        epin:this.state.formNewInput.epin,
        TID:this.props.INSERT_TRANSACTION_LOG.ID,
        referby:this.props.userDetail.REFERBY,
        name:this.props.userDetail.NAME,
        refer_ID:this.props.userDetail.REFER_ID,
        my_plan_ID:1,
      }
      //alert(JSON.stringify(fdata))
      this.state.formNewInput.flag=1
      this.props.insertEpinRegistrationDetail("insertEpinRegistrationDetail",fdata)
      this.setState({loading:true},()=>{console.log("okkkkk")})
    }
    const onlinePaymentDetail=this.state.onlinePaymentDetail;
    this.state.onlinePaymentDetail.amount=this.props.productList.company_amount
    this.state.onlinePaymentDetail.name=this.props.userDetail.NAME
    this.state.onlinePaymentDetail.mobile=this.props.userDetail.MOBILE
    this.state.onlinePaymentDetail.email=this.props.userDetail.EMAIL
    //alert(JSON.stringify(this.props.userDetail.active))
    return (
      <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'>
        <Card>
          <CardBody>
            <div class="row">
              <div class="col-xs-12 col-md-7">
                    <Tabs>
                      <TabList>
                        <Tab>Online</Tab>
                        <Tab>E-Pin</Tab>                   
                      </TabList>
                      <TabPanel>
                        <Card style={{border: "none" }}>
                          <CardBody>
                            <PaymentOnline onTransactionLog={()=>this.onTransactionLog(this.state.onlinePaymentDetail)} userDetail={this.props.userDetail} productList={this.props.productList} />
                          </CardBody>
                        </Card>
                      </TabPanel>
                      <TabPanel>
                        <Card style={{border: "none" }}>
                          <CardBody>
                        {

                         this.props.userDetail.active==undefined?<></>:this.props.userDetail.active==0?<PaymentEpin onSubmit={()=>this.onSubmitEpin(this.state.onlinePaymentDetail)}  userDetail={this.props.userDetail} productList={this.props.productList} formNewInputChange={this.formNewInputChange} formNewInput={this.state.formNewInput} onAgree={()=>this.onAgree()} />:<PaymentPaid />

                         
                        }
                          </CardBody>
                        </Card>
                      </TabPanel>
                  </Tabs>
              </div>
              <div class="col">
                <Card >
                  <CardBody>
                    <OrderDetail userDetail={this.props.userDetail} productList={this.props.productList} />
                  </CardBody>
                </Card>
              </div>  
            </div>
          </CardBody>
        </Card>
      </LoadingOverlay >
    )
  }

//// Class Ends Here
}

const PaymentPaid=({})=>{
  return(
    <Card style={{border: "none" }}>
        <CardBody>
            <Row>
                <Col>
                    <FormGroup>
                      <Label for="">Payment Received, Sponser Approval is Pending, Please Contact Your Sponser to Activate your Account</Label>
                      
                    </FormGroup>
                 
                   


                </Col>
            </Row>
        </CardBody>
    </Card>

  )
}


const PaymentOnline = ({onTransactionLog, userDetail,productList}) => {
      return(   
        <Card style={{border: "none" }}>
            <CardBody>
            <Label for="Amount" style={{color:"red", fontWeight:"bold"}}>Currently Online Payment is Not Active, Please Contact Admin for EPIN </Label>
                <Row>
                   <Col >
                        <FormGroup>
                          <Label for="Amount">Amount</Label>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="amount"
                              id="amount"
                              value={productList.company_amount}   
                              disabled={true}                            
                            />
                        </FormGroup>
                    
                    
                        <FormGroup>
                          <Label for="name">Name</Label>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="name"
                              id="name"
                              disabled={true}                            
                              value={userDetail.NAME}                                                 
                            />
                        </FormGroup>
                        <FormGroup>
                          <Label for="email">Email</Label>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="email"
                              id="email"
                              value={userDetail.EMAIL}      
                              disabled={true}                                               
                            />
                        </FormGroup>
                        
                      
                        <FormGroup>
                          <Label for="mobile">Mobile No</Label>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="mobile"
                              id="mobile"
                              value={userDetail.MOBILE}                         
                              disabled={true} 
                            />
                        </FormGroup>
                        {/* <Button size="sm" color="primary"  onClick={onTransactionLog} >Pay Now </Button> */}
                        
                    </Col>
                </Row>
            </CardBody>
        </Card>
      )
}

const PaymentEpin=({onSubmit,userDetail,productList,formNewInputChange,formNewInput,onAgree})=>{
  return(
    <Card style={{border: "none" }}>
        <CardBody>
            <Row>
                <Col>
                    <FormGroup>
                      <Label for="referby">Refer By</Label>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="referby"
                          id="referby"
                          value={userDetail.REFERBY}    
                          disabled={true}                           
                        />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Enter E-Pin</Label>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="epin"
                          id="epin"
                          value={formNewInput.epin}
                          onChange={formNewInputChange}
                        />
                    </FormGroup>
                   


                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox"  onClick={onAgree}/> I agree to <a  href="/terms-and-condition" target="_blank">terms and condtions </a>
                      </Label>
                    </FormGroup>
                    <br/>
                    <Button size="sm" color="primary"  onClick={onSubmit} >Submit </Button> 
                </Col>
            </Row>
        </CardBody>
    </Card>

  )
}



const OrderDetail=({userDetail,productList})=>{
  ///alert(JSON.stringify(productList))
  return(
    <Card style={{border: "none" }}>
      <CardBody>
          <Row>
              <Col>
                <CardTitle><b>{productList.product_name} </b></CardTitle>
                <hr/>
                <CardText>Date of Purchase: {new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.now())}</CardText>
                <CardText>Golden Tour Package : * 19999/- </CardText>
                <small>
                <CardText>Booking Amount Rs. {productList.price_per_unit}/-
                <br/>
                  Pair Commission {productList.pair_commission}/-
                </CardText>
                <hr/>
                  </small>
                <CardText><b>Net Payable Rs {productList.company_amount}/-</b></CardText>
            
              </Col>
          </Row>
      </CardBody>
    </Card>

  )
}




export default  PaymentForm;