import Page from 'components/Page';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownItem, 
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,ModalHeader,

} from 'reactstrap';
import AweDataTable from 'components/MyWidget/AweDataTable';
import LoadingOverlay from 'react-loading-overlay'
 // Redux Import FIle Start
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import SignatureCanvas from 'react-signature-canvas'
// Redux Import File Ends
const tableTypes = [''];

const log = (type) => console.log.bind(console, type);
class Signature  extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      trimmedDataURL: null,
      loading:false,
      modal: false,
    }
  }

  clear = () => { this.sigPad.clear()  }
  trim = () => {
    let data={
      user_ID:this.props.LOGIN_CHECK.user_ID,
      blob:this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    }
    this.props.userInsertSignature("userInsertSignature",data)
  }
  submit=()=>{
    if (this.state.trimmedDataURL==null){
      alert("File Not Uploaded")
    }else{
      let data={
        user_ID:this.props.LOGIN_CHECK.user_ID,
      }
      this.props.userUpdateSignatureStatus("userUpdateSignatureStatus",data)
    }
  }
  logout=()=>{
    this.props.history.push({pathname: '/', })
  }

  render() {
    let url=this.state.trimmedDataURL==null?"":this.state.trimmedDataURL
    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'100vh', color:'white' }}>
        <div className="col-md-5"  >
          <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'
                >
              By Signing this, You have read and agree with the <a href={"pashupati_Form_back_page-0001.jpg"} target="_blank"> Terms & Condition </a> of the Pashupati Toursim.
              <br/>
              Please Sign in white space<br/>
            <SignatureCanvas penColor='black' backgroundColor="white" dotSize={1} canvasProps={{width: 300, height: 150, className: 'sigCanvas'}}  ref={(ref) => { this.sigPad = ref }} />
            <br/>
            <button  onClick={this.clear}>
              Clear
            </button>  &nbsp;
            <button  onClick={this.trim}>
              Upload
            </button>
            {this.state.trimmedDataURL
            ? <> Uploaded Sign View
                <img src={url} /> </>: null }
            <p>
              <br/>
              Once Signature Submitted Cannot be Change <br/>
              <button  onClick={this.submit}>Final Submit</button>  &nbsp;
              <button  onClick={this.logout}>Logout</button>
            </p>
            
              
              
          </LoadingOverlay>
        </div>
      </div>
    );
  }
  componentWillMount(){}
  
  componentDidMount() {
    console.log("test")
    if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
      let data={
        user_ID:this.props.LOGIN_CHECK.user_ID,
      }
      this.props.userViewSignature("userViewSignature",data)
    }else{
      this.props.history.push({pathname: '/'})
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading:false},()=>{console.log("okkkkk")})
    console.log("yes")
    console.log(nextProps)
    if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID==0){
      nextProps.history.push({pathname: '/'  })
    }else{
     
      if (nextProps.TYPE=="USER_VIEW_SIGNATURE"){
        if (nextProps.USER_VIEW_SIGNATURE!=null){
          this.setState({trimmedDataURL:nextProps.USER_VIEW_SIGNATURE.signature},()=>{console.log("okkkkk")})
        }
      }else if (nextProps.TYPE=="USER_INSERT_SIGNATURE"){
        let data={
          user_ID:this.props.LOGIN_CHECK.user_ID,
        }
        this.props.userViewSignature("userViewSignature",data)
      }else if (nextProps.TYPE=="USER_UPDATE_SIGNATURE_STATUS"){
        if (nextProps.USER_UPDATE_SIGNATURE_STATUS!=null){
          if (nextProps.LOGIN_CHECK.level=="1"){
            this.props.history.push({pathname: '/dashboard',state: { userID:nextProps.LOGIN_CHECK.user_ID } })
          }else{
            this.props.history.push({pathname: '/user-dashboard',state: { userID:nextProps.LOGIN_CHECK.user_ID } })
         }
        }
      }
    }   
  }
//End of AdminViewCompany
}


function mapStateToProps({ mainReducers}) {
   return {
     TYPE:mainReducers.TYPE,
     LOGIN_CHECK:mainReducers.LOGIN_CHECK,
     USER_INSERT_SIGNATURE:mainReducers.USER_INSERT_SIGNATURE,
     USER_VIEW_SIGNATURE:mainReducers.USER_VIEW_SIGNATURE,
     USER_UPDATE_SIGNATURE_STATUS:mainReducers.USER_UPDATE_SIGNATURE_STATUS,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signature) ;
