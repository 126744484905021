/*****************SETUP**********************/
export const USER_REGISTRATION="USER_REGISTRATION"
export const USER_REGISTRATION_CHECK="USER_REGISTRATION_CHECK"
export const USER_REGISTER_ACTIVATE="USER_REGISTER_ACTIVATE"
export const LOGIN_CHECK="LOGIN_CHECK"
export const SIDEBAR_MENU="SIDEBAR_MENU"
export const USER_REGISTERED_LIST_VIEW="USER_REGISTERED_LIST_VIEW"
export const NETWORK_TREE="NETWORK_TREE"
export const ADMIN_ADD_NEW_USER="ADMIN_ADD_NEW_USER"
export const ADMIN_USER_LIST_VIEW="ADMIN_USER_LIST_VIEW"
export const USER_GENERATE_PIN="USER_GENERATE_PIN"
export const ADD_TO_CART="ADD_TO_CART"
export const CLEAR_CART="CLEAR_CART"
export const PRODUCT_LIST="PRODUCT_LIST"
export const INSERT_TRANSACTION_REG="INSERT_TRANSACTION_REG"
export const LOGOUT_CHECK="LOGOUT_CHECK"
export const PACKAGE_LIST="PACKAGE_LIST"
export const PLAN_UPGRADE="PLAN_UPGRADE"
export const USER_DETAIL="USER_DETAIL"
export const BANK_DETAIL_UPDATE="BANK_DETAIL_UPDATE"
export const BANK_DETAIL_VIEW="BANK_DETAIL_VIEW"
export const KYC_DETAIL_UPDATE="KYC_DETAIL_UPDATE"
export const USER_DETAIL_VIEW="USER_DETAIL_VIEW"
export const USER_DETAIL_UPDATE="USER_DETAIL_UPDATE"
export const USER_ADD_MEMBER="USER_ADD_MEMBER"
export const USER_EPIN_DETAIL="USER_EPIN_DETAIL"
export const USER_SUPPORT_TICKET_INSERT="USER_SUPPORT_TICKET_INSERT"
export const USER_SUPPORT_TICKET_VIEW="USER_SUPPORT_TICKET_VIEW"
export const USER_SUPPORT_TICKET_TIMELINE="USER_SUPPORT_TICKET_TIMELINE"
export const ADMIN_EPIN_DETAIL="ADMIN_EPIN_DETAIL"
export const ADMIN_EPIN_UPDATE="ADMIN_EPIN_UPDATE"
export const ADMIN_SUPPORT_TICKET_LIST="ADMIN_SUPPORT_TICKET_LIST"
export const ADMIN_ALL_USERS_LIST="ADMIN_ALL_USERS_LIST"
export const ADMIN_USER_VERIFICATION="ADMIN_USER_VERIFICATION"
export const ADMIN_APPROVE_USER_VERIFICATION="ADMIN_APPROVE_USER_VERIFICATION"
export const INSERT_TRANSACTION_LOG="INSERT_TRANSACTION_LOG"
export const INSERT_EPIN_TRANSACTION_LOG="INSERT_EPIN_TRANSACTION_LOG"
export const INSERT_EPIN_ONLINE_PAYMENT_DETAIL="INSERT_EPIN_ONLINE_PAYMENT_DETAIL"
export const INSERT_EPIN_POINT_PAYMENT_DETAIL="INSERT_EPIN_POINT_PAYMENT_DETAIL"
export const INSERT_EPIN_REGISTRATION_DETAIL="INSERT_EPIN_REGISTRATION_DETAIL"
export const ADMIN_DASHBOARD="ADMIN_DASHBOARD"
export const USER_DASHBOARD="USER_DASHBOARD"
export const REQUEST_OTP="REQUEST_OTP"
export const USER_PLAN_UPGRADE="USER_PLAN_UPGRADE"
export const EPIN_TRANSFER_REQUEST_OTP="EPIN_TRANSFER_REQUEST_OTP"
export const EPIN_TRANSFER_CONFIRM="EPIN_TRANSFER_CONFIRM"
export const WALLET_DETAIL="WALLET_DETAIL"
export const WALLET_TRANSFER_REQUEST_OTP="WALLET_TRANSFER_REQUEST_OTP"
export const WALLET_TRANSACTION_DETAIL="WALLET_TRANSACTION_DETAIL"
export const WALLET_TRANSFER_CONFIRM="WALLET_TRANSFER_CONFIRM"
export const KYC_FINAL_SUBMIT="KYC_FINAL_SUBMIT"
export const USER_REQUEST_OTP="USER_REQUEST_OTP"
export const USER_UPDATE_PASSWORD="USER_UPDATE_PASSWORD"
export const USER_TEAM_LIST="USER_TEAM_LIST"
export const VIEW_SEARCH_TABLE="VIEW_SEARCH_TABLE"
export const VIEW_WALLET_WITHDRAWAL_DETAIL="VIEW_WALLET_WITHDRAWAL_DETAIL"
export const UPDATE_WALLET_WITHDRAWAL="UPDATE_WALLET_WITHDRAWAL"
export const USER_NOMINEE_DETAIL="USER_NOMINEE_DETAIL"
export const USER_NOMINEE_UPDATE_DETAIL="USER_NOMINEE_UPDATE_DETAIL"
export const ADMIN_USER_LIST_FOR_RECEIPT="ADMIN_USER_LIST_FOR_RECEIPT"
export const USER_INVOICE_DETAIL="USER_INVOICE_DETAIL"
export const ADMIN_CREATE_INVOICE="ADMIN_CREATE_INVOICE"
export const USER_INVOICE_LIST="USER_INVOICE_LIST"
export const ADMIN_ROYALTY_USER_LIST="ADMIN_ROYALTY_USER_LIST"
export const ADMIN_ROYALTY_PAYOUT_DETAIL="ADMIN_ROYALTY_PAYOUT_DETAIL"
export const ADMIN_ROYALTY_PAID_DETAIL="ADMIN_ROYALTY_PAID_DETAIL"
export const USER_UNPAID_MEMBERS="USER_UNPAID_MEMBERS"
export const USER_ACTIVATE_MEMBER="USER_ACTIVATE_MEMBER"
export const USER_PAID_MEMBERS="USER_PAID_MEMBERS"
export const USER_TREE_INSERT="USER_TREE_INSERT"
export const USER_COMMISSION_REPORT="USER_COMMISSION_REPORT"
export const COMMISSION_PAY="COMMISSION_PAY"
export const PAY_BONUS="PAY_BONUS"
export const PAY_DATES="PAY_DATES"
export const USER_SILVER_COMMISSION_PAY="USER_SILVER_COMMISSION_PAY"
export const USER_GOLD_COMMISSION_PAY="USER_GOLD_COMMISSION_PAY"
export const USER_EPIN_AVAILABLE_LIST="USER_EPIN_AVAILABLE_LIST"
export const ADMIN_WALLET_WITHDRAWAL_LIST="ADMIN_WALLET_WITHDRAWAL_LIST"
export const ADMIN_WALLET_BATCH_LIST="ADMIN_WALLET_BATCH_LIST"
export const ADMIN_VIEW_BATCH="ADMIN_VIEW_BATCH"
export const ADMIN_WALLET_UPDATE_BATCH_PAYMENT="ADMIN_WALLET_UPDATE_BATCH_PAYMENT"
export const RAZORPAY_CREATE_ORDER="RAZORPAY_CREATE_ORDER"
export const USER_EMI_LIST="USER_EMI_LIST"
export const USER_EMI_DETAIL="USER_EMI_DETAIL"
export const USER_EMI_UPDATE="USER_EMI_UPDATE"
export const ADMIN_USER_EMI_APPROVE="ADMIN_USER_EMI_APPROVE"
export const USER_EMI_RECEIPT="USER_EMI_RECEIPT"
export const ADMIN_UPDATE_USER_DETAIL="ADMIN_UPDATE_USER_DETAIL"
export const USER_INSERT_SIGNATURE="USER_INSERT_SIGNATURE"
export const USER_VIEW_SIGNATURE="USER_VIEW_SIGNATURE"
export const USER_UPDATE_SIGNATURE_STATUS="USER_UPDATE_SIGNATURE_STATUS"
