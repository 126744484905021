import * as ActionTypes from './ActionTypes'
import axios from 'axios';
const wApi = async (params) => {
    //console.log("wapi");
    //console.log("Action page");
    //console.log(params);
    const res = await axios({
    method: 'post',
    //url:'https://api.trust2code.com/pashupati/test_pashupati.php',
    //url:'https://api.samirya.com/app.thepashupatifoundation.com/pashupati_live.php',
    url:'https://api.allexamonline.com/app.pashupatitourism.com/pashutourlive.php',
    data:params,
    crossDomain: true,
    headers: {
        'Content-type': 'multipart/form-data'
      }
  })
  .catch(error => {
    console.log("error")
    console.log(error)
  });
  return res.data;
};

/*  ******************************************/
export const userUpdateSignatureStatus=(hook,formdata)=>{
  //alert(JSON.stringify(formdata))
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_UPDATE_SIGNATURE_STATUS,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userViewSignature=(hook,formdata)=>{
  //alert(JSON.stringify(formdata))
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_VIEW_SIGNATURE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userInsertSignature=(hook,formdata)=>{
  //alert(JSON.stringify(formdata))
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_INSERT_SIGNATURE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const adminUpdateUserDetail=(hook,formdata)=>{
  //alert(JSON.stringify(formdata))
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_UPDATE_USER_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userEmiReceipt=(hook,formdata)=>{
  //alert(JSON.stringify(formdata))
  return(dispatch,getState)=>{
    dispatch({
      type:ActionTypes.USER_EMI_RECEIPT,
      payload:formdata
    })
  }
}


/*  ******************************************/
export const adminUserEmiApprove=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_USER_EMI_APPROVE,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const userEmiUpdate=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_EMI_UPDATE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userEmiDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_EMI_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  ******************************************/
export const userEmiList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_EMI_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  ******************************************/
export const razorpayCreateOrder=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.RAZORPAY_CREATE_ORDER,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const adminWalletUpdateBatchPayment=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_WALLET_UPDATE_BATCH_PAYMENT,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const adminViewBatch=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_VIEW_BATCH,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  ******************************************/
export const adminWalletBatchList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_WALLET_BATCH_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const adminWalletWithdrawalList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_WALLET_WITHDRAWAL_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}



/****************************************   USER EPIN AVAILABLE LIST ******************************************/
export const userAvailabeEpinDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_EPIN_AVAILABLE_LIST,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userGoldCommissionPay=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_GOLD_COMMISSION_PAY,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const userSilverCommissionPay=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SILVER_COMMISSION_PAY,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const payDates=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.PAY_DATES,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const payBonus=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.PAY_BONUS,
        payload:response
      })
    }).catch(err=>{})
  }
}
/*  ******************************************/
export const userRegisterActivate=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_REGISTER_ACTIVATE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userRegistrationCheck=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_REGISTRATION_CHECK,
        payload:response
      })
    }).catch(err=>{})
  }
}




/*  ******************************************/
export const userCommissionPay=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.COMMISSION_PAY,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const userCommissionReport=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_COMMISSION_REPORT,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userTreeInsert=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_TREE_INSERT,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userPaidMembers=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_PAID_MEMBERS,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const userActivateMember=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_ACTIVATE_MEMBER,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const userUnpaidMembers=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_UNPAID_MEMBERS,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const adminRoyaltyPaidtDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_ROYALTY_PAID_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const adminRoyaltyPayoutDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_ROYALTY_PAYOUT_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const adminRoyaltyUserList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_ROYALTY_USER_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  ******************************************/
export const userInvoiceList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_INVOICE_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}
/*  ******************************************/
export const adminCreateInvoice=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_CREATE_INVOICE,
        payload:response
      })
    }).catch(err=>{})
  }
}


/*  ******************************************/
export const userInvoiceDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_INVOICE_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}

/*  ******************************************/
export const adminUserListForReceipt=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_USER_LIST_FOR_RECEIPT,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* USER_NOMINEE_UPDATE_DETAIL ******************************************/
export const userNomineeUpdateDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_NOMINEE_UPDATE_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* USER NOMINEE VIEW ******************************************/
export const userNomineeView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_NOMINEE_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* UPDATE_WALLET_WITHDRAWAL ******************************************/
export const walletPointsWithdrawalConfirm=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.UPDATE_WALLET_WITHDRAWAL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* USER_MY_TEAM ******************************************/
export const viewWithdrawalRequestDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_WALLET_WITHDRAWAL_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* USER_MY_TEAM ******************************************/
export const viewSearchTable=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_SEARCH_TABLE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* USER_MY_TEAM ******************************************/
export const userTeamList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_TEAM_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}



/* USER UPDATE PASSWORD ******************************************/
export const userUpdatePassword=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_UPDATE_PASSWORD,
        payload:response
      })
    }).catch(err=>{})
  }
}



/* USER REQUEST OTP ******************************************/
export const userRequestOTP=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_REQUEST_OTP,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* KYC_FINAL_SUBMIT ******************************************/
export const kycFinalSubmit=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.KYC_FINAL_SUBMIT,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* WALLET Transaction Confirm ******************************************/
export const walletPointsTransferConfirm=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.WALLET_TRANSFER_CONFIRM,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* WALLET Transaction Detail ******************************************/
export const walletTransactionDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.WALLET_TRANSACTION_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* WALLET TRANSFER REQUEST OTP ******************************************/
export const walletTransferRequestOTP=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.WALLET_TRANSFER_REQUEST_OTP,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* WALLET DETAIL ******************************************/
export const walletDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.WALLET_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* EPIN TRANSFER Confirm ******************************************/
export const epinTransferConfirm=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.EPIN_TRANSFER_CONFIRM,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* EPIN TRANSFER Request OTP ******************************************/
export const epinTransferRequestOTP=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.EPIN_TRANSFER_REQUEST_OTP,
        payload:response
      })
    }).catch(err=>{})
  }
}



/**************************************** Request OTP ******************************************/
export const userPlanUpgrade=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_PLAN_UPGRADE,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Request OTP ******************************************/
export const requestOTP=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.REQUEST_OTP,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** Transaction Log ******************************************/
export const userDashboard=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_DASHBOARD,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** Transaction Log ******************************************/
export const adminDashboard=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_DASHBOARD,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** Transaction Log ******************************************/
export const insertEpinRegistrationDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_EPIN_REGISTRATION_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Transaction Log ******************************************/
export const insertEpinPointPaymentDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_EPIN_POINT_PAYMENT_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Transaction Log ******************************************/
export const insertEpinOnlinePaymentDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_EPIN_ONLINE_PAYMENT_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Transaction Log ******************************************/
export const insertEpinTransactionLog=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_EPIN_TRANSACTION_LOG,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Transaction Log ******************************************/
export const insertTransactionLog=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_TRANSACTION_LOG,
        payload:response
      })
    }).catch(err=>{})
  }
}



/**************************************** Admin User Verification ******************************************/
export const adminApproveUserVerification=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_APPROVE_USER_VERIFICATION,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Admin User Verification ******************************************/
export const adminUserVerification=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_USER_VERIFICATION,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Admin All User List ******************************************/
export const adminAllUsersList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_ALL_USERS_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Admin Support Tickt List ******************************************/
export const adminViewSupportTicketList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_SUPPORT_TICKET_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** Admin Epin Update ******************************************/
export const updateEPinDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_EPIN_UPDATE,
        payload:response
      })
    }).catch(err=>{})
  }
}



/**************************************** Admin Epin Detail ******************************************/
export const adminEpinDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_EPIN_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** User Support Ticket Timeline ******************************************/
export const userSupportTicketTimeline=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SUPPORT_TICKET_TIMELINE,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** User Support Ticket View ******************************************/
export const userSupportTicketView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SUPPORT_TICKET_VIEW,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** User Support Ticket Insert ******************************************/
export const userSupportTicketInsert=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SUPPORT_TICKET_INSERT,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** User  EPin Detail ******************************************/
export const userEPinDetailByID=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_EPIN_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/**************************************** User  Detail Update ******************************************/
export const userAddMember=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_ADD_MEMBER,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** User  Detail Update ******************************************/
export const userDetailUpdate=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_DETAIL_UPDATE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** User  Detail View ******************************************/
export const userDetailView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_DETAIL_VIEW,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** KYC Detail Update  ******************************************/
export const kycDetailUpdate=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.KYC_DETAIL_UPDATE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** Bank Detail View  ******************************************/
export const bankDetailView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.BANK_DETAIL_VIEW,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** Bank Detail Update  ******************************************/
export const bankDetailUpdate=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.BANK_DETAIL_UPDATE,
        payload:response
      })
    }).catch(err=>{})
  }
}

/**************************************** User Detail  ******************************************/
export const userDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}



/****************************************  PACKAGE  ******************************************/
export const loadPackage=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.PACKAGE_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}


/****************************************  LOGOUT  ******************************************/
export const logoutCheck=(hook,formdata)=>{
  return(dispatch,getState)=>{
    dispatch({
        type:ActionTypes.LOGOUT_CHECK,
        payload:''
      })
  }
}


/****************************************  INSERT_TRANSACTION_REG  ******************************************/
export const insertRegistrationFeeDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_TRANSACTION_REG,
        payload:response
      })
    }).catch(err=>{})
  }
}



/****************************************   PRODUCT LIST  ******************************************/
export const productList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.PRODUCT_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}


/****************************************  ADD TO CART  ******************************************/
export const addToCart=(hook,formdata)=>{
  return(dispatch,getState)=>{
      dispatch({
        type:ActionTypes.ADD_TO_CART,
        payload:formdata
      })
  }
}

/****************************************  CLEAR CART  ******************************************/
export const clearCart=(hook,formdata)=>{
  return(dispatch,getState)=>{
      dispatch({
        type:ActionTypes.CLEAR_CART,
        payload:formdata
      })
  }
}

/****************************************   USER_GENERATE_PIN  ******************************************/
export const userGeneratePin=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_GENERATE_PIN,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


/****************************************   ADMIN ADD NEW USER  ******************************************/
export const adminUserListView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_USER_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************************   ADMIN ADD NEW USER  ******************************************/
export const adminAddNewUser=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_ADD_NEW_USER,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************************   NETWORK TREE  ******************************************/
export const userNetworkTree=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.NETWORK_TREE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************************   USER REGISTERED LIST VIEW  ******************************************/
export const userRegisteredListView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_REGISTERED_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************************   Sidebar Menu  ******************************************/
export const viewSidebarMenu=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.SIDEBAR_MENU,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************************   User Login  ******************************************/
export const loginCheck=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.LOGIN_CHECK,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************************   User Registration  ******************************************/
export const userRegistration=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_REGISTRATION,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

  // dispatch({type:ActionTypes.SHOW_PHONE_SIGNUP_FORM_LOADING})   
     // alert(JSON.stringify(response.isconfirm)) 
       // if(response.isconfirm=="YES" && response.ispasswordset!=""){ jumpTo(2);}