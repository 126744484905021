import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Label,
} from 'reactstrap';
import bn from 'utils/bemnames';
 // Redux Import FIle Start
 import { connect } from "react-redux";
 import { ActionCreators } from "actions";
 import { bindActionCreators } from "redux";
 import * as ActionTypes from "actions/ActionTypes";
 // Redux Import File Ends



import user1Image from 'assets/img/users/100_1.jpg';
import user2Image from 'assets/img/users/100_2.jpg';
import user3Image from 'assets/img/users/100_3.jpg';
import user4Image from 'assets/img/users/100_4.jpg';
import user5Image from 'assets/img/users/100_5.jpg';



const bem = bn.create('header');


const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small></small>,
})(MdNotificationsActive);

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      notificationsData:[],
      profileData:[],
    }
  }


  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  onSignout=event=>{
    this.props.logoutCheck("logout",'');
  }
  

  render() {
    const { isNotificationConfirmed } = this.state;
    //alert(JSON.stringify(this.props))
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline color="primary" onClick={this.handleSidebarControlButton}>
            <MdClearAll size={20} />
          </Button>
        </Nav>
        <Nav navbar>
          {//<SearchInput />
  }
        </Nav>
       
        <Label style={{fontSize:14}}>WELCOME, {this.props.LOGIN_CHECK.NAME} &nbsp;</Label>  
        {/*<Label  style={{color:"#23B7E5", fontSize:22,fontWeight: 'bold' }}>{this.props.LOGIN_CHECK.NAME} </Label>*/}
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
          
           <NavLink id="Popover1">
              <ListGroup flush>
                <ListGroupItem tag="button" onClick={()=>this.onSignout()} action className="border-light">
                    Signout
                </ListGroupItem>
              </ListGroup>
            </NavLink>
          </NavItem>

          
        </Nav>
      </Navbar>
    );
  }

  componentDidMount() {
    let data={'userID':this.props.LOGIN_CHECK.user_ID}
   // this.props.viewProfile("viewProfile",data);
    //  console.log("didmount header")
  }

  componentWillReceiveProps(nextProps) {
   
     if (nextProps.TYPE=="VIEW_PROFILE"){
     //this.setState({notificationsData:nextProps.VIEW_NOTIFICATION.VIEW_NOTIFICATION})
       this.setState((state) => {
         return{
           notificationsData:nextProps.VIEW_PROFILE.VIEW_NOTIFICATION,
           profileData:nextProps.VIEW_PROFILE.VIEW_PROFILE,
         }
       },()=>{})
    }
  
  }



  /////Class Ends Here
}
function mapStateToProps({ mainReducers}) {
   return {
     TYPE:mainReducers.TYPE,
     VIEW_PROFILE:mainReducers.VIEW_PROFILE,
     LOGIN_CHECK:mainReducers.LOGIN_CHECK,
     //history:mainReducers.history,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header) ;

