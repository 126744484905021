import logo200Image from 'assets/img/logo/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col,ModalHeader,Card,CardBody } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay';
import useRazorpay, { RazorpayOptions } from "razorpay";

const Razorpay = require('razorpay')
class UserOfflineRegistration extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        username:"",
        name:'',
        password:'',
        email:'',
        mobile:'',
        referby:'',
        parent_ID:'',
        epin:"",
        side:"",
        amount:0,
        month_emi:0,
        paymode:"",
        paymodedetail:"",
        dop:"",
        remark:"",
        address:"",
        my_plan_ID:"",
      },
      paymentStatus:"NO", 
      loading:false,
      modal: false,
    }
  } 
  
  onCheck = event => {
    if (this.state.formNewInput.name===""){
      alert("Name is Empty")
    }
    else if (this.state.formNewInput.username===""){
      alert("Username is Empty")
    }
    else if (this.state.formNewInput.address===""){
      alert("Address is Empty")
    }
    else if (this.state.formNewInput.password===""){
      alert("Password is Empty")
    }
    else if (this.state.formNewInput.password.length<4){
      alert("Password Should be 4 or More Digit ")
    }
    else if (this.state.formNewInput.mobile===""){
      alert("Mobile is Empty")
    }
    else if (this.state.formNewInput.email===""){
      alert("Email  is Empty")
    }
    else if (!validate(this.state.formNewInput.email)) {
      alert("Email ID is Invalid")
    }
    else if (this.state.formNewInput.mobile.length!=10){
      alert("Mobile Number Should be 10 Digit Number "+this.state.formNewInput.mobile.length)
    }
    else if (isNaN(this.state.formNewInput.mobile)){
      alert("Please Check the Mobile Number")
    }
    else if (this.state.formNewInput.referby===""){
      alert("Refer By  is Empty")
    }
    else{
      //alert(JSON.stringify(this.state.formNewInput))
      this.props.userRegistrationCheck("userOfflineRegistraionCheck",this.state.formNewInput)
      this.setState({loading:true},()=>{console.log("okkkkk")})
    }
  };

  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    //console.log(this.state.formNewInput)
    //console.log("value"+value)
  }

  toggle = (modalType) => {
    if (!modalType) { return this.setState({   modal: !this.state.modal,});   }
    this.setState({    [`modal_${modalType}`]: !this.state[`modal_${modalType}`],    });
  };
  
  onSubmit = () => {
    //alert(JSON.stringify(this.state.formNewInput))
    if (this.state.formNewInput.paymode===""){
      alert("Paymode is Empty")
    }
    else if (this.state.formNewInput.paymodedetail===""){
      alert("Paymode Detail is Empty")
    }
    else if (this.state.formNewInput.dop===""){
      alert("Date of Payment is Empty")
    }
    else if (this.state.formNewInput.remark===""){
      alert("Remark is Empty")
    }
    else{
      //alert(JSON.stringify(this.state.formNewInput))
      
      this.props.userRegisterActivate("userRegisterActivate",this.state.formNewInput)
      this.setState({loading:true},()=>{console.log("okkkkk")})
    }
  };

  onSubmit2 = () => {
    //alert(JSON.stringify(this.state.formNewInput))
    this.state.formNewInput.paymode=""
    this.state.formNewInput.paymodedetail=""
    this.state.formNewInput.dop=""
    this.state.formNewInput.remark=""
    this.props.userRegisterActivate("userRegisterActivate",this.state.formNewInput)
    this.setState({loading:true},()=>{console.log("okkkkk")})
  };
  
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (  
      <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'  >
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'130vh', color:'white' }}>
        <div className="col-md-3"  >
          <Form onSubmit={this.handleSubmit} >
            {showLogo && (
              <div className="text-center">
                <img
                  src={logo200Image}
                  className="rounded"
                  style={{ width: 160, height: 80, cursor: 'pointer' }}
                  alt="logo"
                />
              
              </div>
            )}
              <FormGroup>
                <Label for="username">Username</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter Username"
                    onChange={this.formNewInputChange}
                    value={this.state.formNewInput.username}
                  />
              </FormGroup>
              <FormGroup>
                <Label for="username">Full Name</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    onChange={this.formNewInputChange}
                    value={this.state.formNewInput.name}
                  />
              </FormGroup>
          
              <FormGroup>
              <Label for="password">Password</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  value={this.state.formNewInput.password}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
              <FormGroup>
              <Label for="mobile">Mobile No</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="Enter Mobile No"
                  value={this.state.formNewInput.mobile}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>

              <FormGroup>
              <Label for="email">Email ID</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter Email ID"
                  value={this.state.formNewInput.email}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
              <FormGroup>
              <Label for="refer">Sponser/Refer By</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="referby"
                  id="referby"
                  placeholder="Enter Refer ID"
                  value={this.state.formNewInput.referby}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
              <FormGroup>
              <Label for="address">Address </Label>
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="address"
                  id="address"
                  placeholder="Enter Address"
                  value={this.state.formNewInput.address}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
              
              <FormGroup>
              <Label for="refer">Package </Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="amount"
                  id="amount"
                  placeholder="Enter Amount"
                  disabled={true}   
                  value={this.state.formNewInput.amount}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
            <hr />
            <Button
              size="lg"
              className="border-0"
              color="primary"
              block
              onClick={this.onCheck}>
              Register
            </Button>
           
            <Row  style={{justifyContent: 'center', alignItems: 'center' }}>
              <Button  style={{color:"Blue"}}  size="lg" className="border-0" color="link" onClick={()=>this.props.history.push('/')}> Back </Button>
            </Row>
            {children}
          </Form>
          <Modal isOpen={this.state.modal}  size="lg">
          <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'  >
            <ModalHeader >Payment Detail</ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={12} lg={12} md={12} >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl={4} lg={4} md={4} >
                          <FormGroup>
                            <small><Label for="">Username</Label></small><br/>
                              <Label>{this.state.formNewInput.username}  </Label>
                          </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} >
                          <FormGroup>
                            <small><Label for="">Full Name</Label></small><br/>
                              <Label>{this.state.formNewInput.name}  </Label>
                          </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} >
                          <FormGroup>
                            <small><Label for="">Sponser/Refer Name</Label></small><br/>
                              <Label>{this.state.formNewInput.referby}  </Label>
                          </FormGroup>
                        </Col>
                        <Col xl={3} lg={3} md={3} >
                          <FormGroup>
                            <Label for="">Pay Mode</Label>
                              <Input type="select" name="paymode" id="paymode" onChange={this.formNewInputChange} value={this.state.formNewInput.paymode} >
                              <option value=""></option>
                              <option value="1">NEFT/RTGS</option>
                              <option value="2">Cheque</option>
                              <option value="3">Other</option>
                               
                              </Input>
                          </FormGroup>
                        </Col>
                        <Col xl={6} lg={6} md={6}>
                          <FormGroup>
                            <Label >Pay Mode Detail </Label>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="paymodedetail"
                                id="paymodedetail"
                                placeholder="Enter Pay Mode Detail"
                                value={this.state.formNewInput.paymodedetail}
                                onChange={this.formNewInputChange}
                              />
                          </FormGroup>
                        </Col>
                        <Col xl={3} lg={3} md={3}>
                          <FormGroup>
                            <Label>Amount</Label>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="amount"
                                id="amount"
                                placeholder="Enter Amount"
                                disabled={true}   
                                value={this.state.formNewInput.month_emi}
                                onChange={this.formNewInputChange}
                              />
                          </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                          <FormGroup>
                            <Label >Date of Payment </Label>
                            <Input
                              type="date"
                              bsSize="sm"
                              name="dop"
                              id="dop"
                              placeholder="Select Date"
                              value={this.state.formNewInput.dop}
                              onChange={this.formNewInputChange}
                            />
                          </FormGroup>
                        </Col>
                        
                        <Col xl={8} lg={8} md={8}>
                          <FormGroup>
                            <Label>Remark </Label>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="remark"
                                id="remark"
                                placeholder="Enter Remark"
                                value={this.state.formNewInput.remark}
                                onChange={this.formNewInputChange}
                              />
                          </FormGroup>
                        </Col>
                                      
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                
                <Col xl={4} lg={4} md={4} >
                </Col>
                <Col xl={4} lg={4} md={4} >
                  <Button size="sm" color="primary" onClick={()=>this.onSubmit()}>
                   Add Now
                  </Button>
              {"   "}
              <Button size="sm" color="danger" onClick={()=>this.onSubmit2()}>
                   Pay Later
                  </Button>
              {"   "}
                <Button size="sm" color="primary" onClick={()=>this.toggle()}>
                Close
              </Button>
              </Col>
                <Col xl={4} lg={4} md={4} >
                  </Col>
              </Row>
            </ModalBody>
            </LoadingOverlay>
          </Modal>
        </div>
      </div>
      </LoadingOverlay>
    );
  }

  componentDidMount() {
    console.log("did mount")
    let data={'my_plan_ID':this.props.location.state.productID}
    this.props.productList("productListNew",data)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading:false},()=>{console.log("okkkkk")})
    console.log("user registeration ")
    console.log(nextProps)
    if (nextProps.TYPE==='USER_REGISTRATION_CHECK'){
      console.log("user registeration next props")
      console.log(nextProps.USER_REGISTRATION_CHECK)
      if (nextProps.USER_REGISTRATION_CHECK.STATUS=="OK"){
        this.toggle()
      }
      else{
        //alert(nextProps.USER_REGISTRATION_CHECK.MSG)
      }
    }
    else if (nextProps.TYPE=="USER_REGISTER_ACTIVATE"){
      console.log("user register acitvate")
      console.log(nextProps.USER_REGISTER_ACTIVATE)
      //alert(JSON.stringify(nextProps.USER_REGISTER_ACTIVATE))
      let data=nextProps.USER_REGISTER_ACTIVATE
      let check=data.USER_REGISTERATION.STATUS   /// ok
      let login=nextProps.USER_REGISTER_ACTIVATE.USER_REGISTERATION.USERNAME ///
      let msg=data.MSG
      if (check=="SUCCESS"){
        alert("You have Registered Successfully ")
        this.toggle()
        this.props.history.push('/');
      }
      else {
        alert(msg)
      }
    }

    else if (nextProps.TYPE=="PRODUCT_LIST"){
      const formTask=this.state.formNewInput
      formTask["amount"]=nextProps.PRODUCT_LIST.company_amount;
      formTask["month_emi"]=nextProps.PRODUCT_LIST.month_emi;
      formTask["my_plan_ID"]=this.props.location.state.productID;
      this.setState({formTask})
    }
    
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserOfflineRegistration.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserOfflineRegistration.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  //console.log("main reducers")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     PRODUCT_LIST:mainReducers.PRODUCT_LIST,
     USER_REGISTRATION_CHECK:mainReducers.USER_REGISTRATION_CHECK,
     USER_REGISTER_ACTIVATE:mainReducers.USER_REGISTER_ACTIVATE,
     RAZORPAY_CREATE_ORDER:mainReducers.RAZORPAY_CREATE_ORDER,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const validate = (email) => {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i

  return expression.test(String(email).toLowerCase())
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOfflineRegistration) ;

