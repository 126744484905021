import logo200Image from 'assets/img/logo/logo_200_white.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col,ModalHeader,Card,CardBody } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'

class UserRegistration extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        name:'',
        password:'',
        email:'',
        mobile:'',
        referby:'',
        parent_ID:'',
        epin:"",
        side:"",
        amount:0,
      },
      loading:false,
      modal: false,
    }
  } 

  onCheck = event => {
    if (this.state.formNewInput.name===""){
      alert("Name is Empty")
    }
    else if (this.state.formNewInput.password===""){
      alert("Password is Empty")
    }
    else if (this.state.formNewInput.password.length<4){
      alert("Password Should be 4 or More Digit ")
    }
    else if (this.state.formNewInput.mobile===""){
      alert("Mobile is Empty")
    }
    else if (this.state.formNewInput.email===""){
      alert("Email  is Empty")
    }
    else if (!validate(this.state.formNewInput.email)) {
      alert("Email ID is Invalid")
    }
    else if (this.state.formNewInput.mobile.length!=10){
      alert("Mobile Number Should be 10 Digit Number "+this.state.formNewInput.mobile.length)
    }
    else if (isNaN(this.state.formNewInput.mobile)){
      alert("Please Check the Mobile Number")
    }
    else if (this.state.formNewInput.referby===""){
      alert("Refer By  is Empty")
    }
    else{
      //alert(JSON.stringify(this.state.formNewInput))
      this.props.userRegistrationCheck("userRegistrationCheck",this.state.formNewInput)
      this.setState({loading:true},()=>{console.log("okkkkk")})
    }
  };

  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    //console.log(this.state.formNewInput)
    //console.log("value"+value)
  }

  toggle = (modalType) => {
    if (!modalType) { return this.setState({   modal: !this.state.modal,});   }
    this.setState({    [`modal_${modalType}`]: !this.state[`modal_${modalType}`],    });
  };
  
  onSubmit = () => {
    
    //alert(JSON.stringify(this.state.formNewInput))
     if (this.state.formNewInput.side===""){
      alert("Side is Empty")
    }
    else{
      //alert(JSON.stringify(this.state.formNewInput))
      this.props.userRegisterActivate("userRegisterActivate",this.state.formNewInput)
      this.setState({loading:true},()=>{console.log("okkkkk")})
    }
  };
  
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'130vh', color:'white' }}>
        <div className="col-md-3"  >
        <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'  >
          <Form onSubmit={this.handleSubmit} >
            {showLogo && (
              <div className="text-center">
                <img
                  src={logo200Image}
                  className="rounded"
                  style={{ width: 160, height: 80, cursor: 'pointer' }}
                  alt="logo"
                />
              
              </div>
            )}
             
              <FormGroup>
                <Label for="username">Name</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    onChange={this.formNewInputChange}
                    value={this.state.formNewInput.name}
                  />
              </FormGroup>
          
              <FormGroup>
              <Label for="password">Password</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  value={this.state.formNewInput.password}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
              <FormGroup>
              <Label for="mobile">Mobile No</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="Enter Mobile No"
                  value={this.state.formNewInput.mobile}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>

              <FormGroup>
              <Label for="email">Email ID</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter Email ID"
                  value={this.state.formNewInput.email}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
              <FormGroup>
              <Label for="refer">Refer By</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="referby"
                  id="referby"
                  placeholder="Enter Refer ID"
                  value={this.state.formNewInput.referby}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
               <FormGroup>
              <Label for="refer">Placement ID</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="parent_ID"
                  id="parent_ID"
                  placeholder="Enter Parent ID"
                  value={this.state.formNewInput.parent_ID}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
               <FormGroup>
              <Label for="refer">EPin</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="epin"
                  id="epin"
                  placeholder="Enter EPIN"
                  value={this.state.formNewInput.epin}
                  onChange={this.formNewInputChange}
                />
              </FormGroup>
            <hr />
            <Button
              size="lg"
              className="border-0"
              color="primary"
              block
              onClick={this.onCheck}>
              Register
            </Button>
           
            <Row  style={{justifyContent: 'center', alignItems: 'center' }}>
              <Button  style={{color:"white"}}  size="lg" className="border-0" color="link" onClick={()=>this.props.history.push('/')}> Sign In </Button>
            </Row>
            {children}
          </Form>
          <Modal isOpen={this.state.modal}  size="lg">
            <ModalHeader >Add To Tree</ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={12} lg={12} md={12} >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl={4} lg={4} md={4} >
                          <FormGroup>
                            <small><Label for="">Refer By</Label></small><br/>
                              <Label>{this.state.formNewInput.referby}  </Label>
                          </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} >
                          <FormGroup>
                            <small><Label for="">Parent Name</Label></small><br/>
                              <Label>{this.state.formNewInput.parent_ID}  </Label>
                          </FormGroup>
                        </Col>
                        <Col xl={4} lg={4} md={4} >
                          <FormGroup>
                            <Label for="">Select Side</Label>
                              <Input type="select" name="side" id="side" onChange={this.formNewInputChange} value={this.state.formNewInput.side} >
                              <option value=""></option>
                                {this.state.side}
                              </Input>
                          </FormGroup>
                        </Col>
                                      
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                
                <Col xl={4} lg={4} md={4} >
                </Col>
                <Col xl={4} lg={4} md={4} >
                <Button size="sm" color="primary" onClick={()=>this.onSubmit()}>
                Add Now
              </Button>
              {"   "}
                <Button size="sm" color="primary" onClick={()=>this.toggle()}>
                Close
              </Button>
              </Col>
                <Col xl={4} lg={4} md={4} >
                  </Col>
              </Row>
            </ModalBody>
          </Modal>
        </LoadingOverlay>
        </div>
      </div>
    );
  }
  componentDidMount() {
    console.log("did mount")
    let data={'product_ID':1}
    this.props.productList("productList",data)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading:false},()=>{console.log("okkkkk")})
    console.log("user registeration ")
    console.log(nextProps)
    if (nextProps.TYPE==='USER_REGISTRATION_CHECK'){
      console.log("user registeration next props")
      console.log(nextProps.USER_REGISTRATION_CHECK)
      if (nextProps.USER_REGISTRATION_CHECK.STATUS=="OK"){
        let items = [];  
        let childCount=nextProps.USER_REGISTRATION_CHECK.tree_check.RESULT.CHILD_COUNT
        let childList=nextProps.USER_REGISTRATION_CHECK.tree_check.RESULT.CHILD_LIST
        if (childCount<2){
          if (childCount==0){
              items.push(<option key={0} value={"left"}>LEFT</option>);   
              items.push(<option key={1} value={"right"}>RIGHT</option>);   
          }
          else if (childCount==1){
            if (childList[0].side=="left"){items.push(<option key={0} value={"right"}>RIGHT</option>);    }
            else{ items.push(<option key={0} value={"left"}>LEFT</option>);   }
          }
          this.setState({side:items,},()=>{console.log("okkkkk")})
          this.toggle()
        }else{     }
      }
      else{
        alert(nextProps.USER_REGISTRATION_CHECK.MSG)
      }
    }
    else if (nextProps.TYPE=="USER_REGISTER_ACTIVATE"){
      console.log("user register acitvate")
      console.log(nextProps.USER_REGISTER_ACTIVATE)
      //alert(JSON.stringify(nextProps.USER_REGISTER_ACTIVATE))
      let data=nextProps.USER_REGISTER_ACTIVATE.CHECK
      let check=data.STATUS   /// ok
      let epin=data.epin.RESULT.ID  //   1
      let tree=data.tree_check.STATUS  ///  SUCCESS
      let login=nextProps.USER_REGISTER_ACTIVATE.USER_REGISTERATION.USERNAME ///
      let msg=data.MSG
      //alert(check+"--"+ epin +"---"+tree+"--"+ login)
      
      if (check=="OK" && epin>0 && tree=="OK" && login!=""){
        alert("You have Registered Successfully You Login ID is "+ login )
        this.toggle()
        this.props.history.push('/');
      }
      else {
        alert(msg)
      }
    }
    
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserRegistration.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserRegistration.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  //console.log("main reducers")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     PRODUCT_LIST:mainReducers.PRODUCT_LIST,
     USER_REGISTRATION_CHECK:mainReducers.USER_REGISTRATION_CHECK,
     USER_REGISTER_ACTIVATE:mainReducers.USER_REGISTER_ACTIVATE,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const validate = (email) => {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i

  return expression.test(String(email).toLowerCase())
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistration) ;

