import logo200Image from 'assets/img/logo/logo_200_white.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
class UserPasswordRecovery extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
       mobile_no:"",
       username:""
      },
      loading:false,
    }
  } 


  handleSubmit = event => {
   //event.preventDefault();
    //alert(event)
    //alert(JSON.stringify(this.state.formNewInput))
    //{"name":"","password":"","email":"","mobile":""}
    if (this.state.formNewInput.mobile_no===""){
      alert("Mobile No is Empty")
    }
    else if (this.state.formNewInput.username==""){
      alert("Username  is Empty")
    }
    else{
      this.props.userRequestOTP("userRequestOTP",this.state.formNewInput);
     // this.setState({loading:true},()=>{console.log("okkkkk")})

    }
  };

  formNewInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    //console.log(this.state.formNewInput)
    //console.log("value"+value)
  }

  onSubmit = () => {
   // alert("ok1")
    this.props.history.push('/');
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'100vh', color:'white' }}>
            <div className="col-md-3"  >
    
       <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'
              >
            <Form onSubmit={this.handleSubmit} >
              {showLogo && (
                <div className="text-center pb-4">
                  <img
                    src={logo200Image}
                    className="rounded"
                    style={{ width: 160, height:150, cursor: 'pointer' }}
                    alt="logo"
                  
                  />
                  <br/>
            <Label for=""> </Label>
                </div>

              )}
                <FormGroup>
                  <Label for="mobile No">Enter Mobile No</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="mobile_no"
                      id="mobile"
                      placeholder="Enter Name"
                      onChange={this.formNewInputChange}
                      
                    />
                </FormGroup>
                 <FormGroup>
                  <Label for="username">Enter Username</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Enter Username"
                      onChange={this.formNewInputChange}
                      
                    />
                </FormGroup>
                
              <hr />
              <Button
                size="sm"
                className="border-0"
                color="primary"
                block
                onClick={this.handleSubmit}>
                Request OTP
              </Button>
              <Row  style={{justifyContent: 'center', alignItems: 'center' }}>
                <Button  style={{color:"white"}} size="sm" className="border-0" color="link" onClick={()=>this.onSubmit()}> Sign In </Button>
              </Row>
              {children}
            </Form>
            
      </LoadingOverlay>

        </div>
     
      </div>
    );
  }

  componentDidMount() {
    console.log("did mount")
    let data={
        'product_ID':1
    }
    this.props.productList("productList",data)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.TYPE==='USER_REGISTRATION'){
     
    }
    else if (nextProps.TYPE==='ADD_TO_CART'){
      nextProps.history.push({ pathname: '/' })
    }
    else if (nextProps.TYPE=="USER_REQUEST_OTP"){
      if (nextProps.USER_REQUEST_OTP.STATUS=="SUCCESS"){
        alert(nextProps.USER_REQUEST_OTP.MSG)
        this.props.history.push({pathname: '/user-otp-request',state: { formNewInput:this.state.formNewInput,otp:nextProps.USER_REQUEST_OTP.OTP } })
      }
      else{
        alert(nextProps.USER_REQUEST_OTP.MSG)
      }

    }

    this.setState({loading:false},()=>{console.log("okkkkk")})
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserPasswordRecovery.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserPasswordRecovery.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducers")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_REQUEST_OTP:mainReducers.USER_REQUEST_OTP
     
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPasswordRecovery) ;

