import Page from 'components/Page';
import React from 'react';
import {
  Row,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Table,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownItem, 
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

import {
  MdBubbleChart,
  MdFilterList,
  MdFilterFrames,
  MdDetails,
} from 'react-icons/md';
/////////////// MLM
class AweDataTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
        defaultTableProps:{
          data: {
          col_names: {},
          rows: []
          },
          page_nav: {
          page_no: 1
          }
        },
        searchFilter:[],
        filterFormData:{},
      }
    }

   filterFormDataChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formSearch=this.state.filterFormData
    formSearch[key]=value;
    this.setState({formSearch},()=>{
      console.log(this.state.filterFormData)
    })
  }

  render() {
    console.log("awe Data table render")
    console.log(this.props);
    //alert(JSON.stringify(this.props.tableSchema))
     if (JSON.stringify(this.props.tableSchema)=="{}" || this.props.tableSchema===undefined){
      return(
        <div></div>
      )

     }else{
      const tableSchema=this.props.tableSchema===undefined?this.state.defaultTableProps:this.props.tableSchema.data===undefined?this.state.defaultTableProps:this.props.tableSchema
      const headerSchema=tableSchema.data.col_names
      const headerFilter=tableSchema.filter
      const rowData=tableSchema.data.rows
      const {page_no}=tableSchema.page_nav
      const {page_count}=tableSchema.page_nav
      const editButtonProperties=this.props.editButtonProperties
      const deleteButtonProperties=this.props.deleteButtonProperties
      return (
        <div>
          {this.props.actionButtonArray.map((item)=>(<Button onClick={()=>this.props.action(item)}>{item} </Button>))
                
          }
          <SearchBar headerSchema={headerSchema} filterFormDataChange={this.filterFormDataChange} btnSearcFilterClick={()=>this.props.onSeachClick(this.state.filterFormData)}
          btnResetSearchFilter={()=>this.props.btnResetSearchFilter()}
          
          />
          <Table size="sm" responsive>
            <TableHeader headerSchema={headerSchema} headerFilter={headerFilter}/>
            <TableData rowData={rowData} onRowEdit={(e)=>this.props.onRowEdit(e)} onRowDelete={(e)=>this.props.onRowDelete(e)} editButtonProperties={editButtonProperties} deleteButtonProperties={deleteButtonProperties} />
          </Table>
          <NavigationButton pageNo={page_no} pageCount={page_count} btnClick={(e)=>this.props.pageClick(e)}  />
        
          {/*this.props.tableSchema.page_nav.page_no<=this.props.tableSchema.page_nav.page_count?
          <Button onClick={()=>this.props.pageClick(this.props.tableSchema.page_nav.page_no+1)}> Next </Button>:null
        */ }
        </div>
      )
    }
  }

//// Class Ends Here
}
  const HeaderFilter = ({headerFilter,...restProps}) => {
      return(   
        <UncontrolledButtonDropdown className="m-0" size="sm">
          <DropdownToggle  outline color="link">
            <MdDetails />
          </DropdownToggle>
            <DropdownMenu>
             {headerFilter===undefined?null:
              headerFilter.map((item) => (
                 <DropdownItem>Value: {item.search_value}</DropdownItem>
              ))
             }
            {JSON.stringify(headerFilter)}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
      )
  }
  const TableHeader = ({headerSchema,headerFilter, ...restProps})=>{
    return(
      <thead>
          <tr>
            {Object.keys(headerSchema).map((key, i) => (
                <th>{headerSchema[key]} {/*<HeaderFilter headerFilter={headerFilter[key]}/>*/}</th>
            ))}
          
            <th>#</th>
          </tr>
      </thead>
    )
  }

  const TableData=({rowData,onRowEdit,onRowDelete,editButtonProperties,deleteButtonProperties,...restProps})=>{
    return(
      <tbody>
         {rowData.map((row, index) => (
            <tr row={index}>
              { Object.values(row).map((cellValue) => (
                <td>{" "}{cellValue}</td>
              ))}
              <td>{editButtonProperties===undefined?<div></div>:editButtonProperties.visible===true?<Button style={{marginRight:"5px"}} size="sm" color="primary" onClick={()=>onRowEdit(row)} > {editButtonProperties.title}</Button>:<div></div>}
              {editButtonProperties===undefined?<div></div>:deleteButtonProperties.visible===true?<Button size="sm" onClick={()=>onRowDelete(row)}> {deleteButtonProperties.title}</Button>:<div></div>}
              
              </td>
            </tr>
         ))}
      </tbody>
    )
  }

  const NavigationButton=({pageNo,pageCount,btnClick,...restProps})=>{

    return(
      <ButtonGroup size="sm" className="mr-3 mb-3">
        <Button color="primary" size="sm" onClick={()=>btnClick(pageNo-1)}> Prev</Button>
        <UncontrolledButtonDropdown>
          <DropdownToggle color="primary" size="sm" caret>{pageNo}</DropdownToggle>
          <DropdownMenu>
          {[...Array(pageCount)].map((e, i) => <DropdownItem>{[i+1]}</DropdownItem>)}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
        <Button color="primary" size="sm" onClick={()=>btnClick(pageNo+1)}>Next</Button>
      </ButtonGroup>
    )
  }

  const SearchBar=({headerSchema,filterFormDataChange,btnSearcFilterClick,btnResetSearchFilter,...restProps})=>{
    return(
      <div class="row" style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
     
          <div className="mr-2">
            <Input type="select" name="col_name" id="col_name" size="sm" onChange={filterFormDataChange}  >
             <option value=""></option>
              {Object.keys(headerSchema).map((key, i) => (
                <option value={key}>{headerSchema[key]}</option>
            ))}
            </Input>
          </div>
          <div className="mr-2">
            <Input type="select" name="search_condition" size="sm" onChange={filterFormDataChange} >
              <option></option>
              <option value="Equal">Equal</option>
              <option value="Like">Like</option>
              <option value="Not Equal">Not Equal</option>
              <option value="Not Like">Not Like</option>
              <option value="Greater">Greater</option>
              <option value="Less">Less</option>
              <option value="Greater Equal">Greater Equal</option>
              <option value="Less Equal">Less Equal</option>
              <option value="Between">Between</option>
            </Input>
          </div>
          <div className="mr-2">
            <Input
                type="text"
                name="search_value"
                placeholder="Enter The Search Value"
                size="sm"
                style={{width:200}}
                onChange={filterFormDataChange}
              />
          </div>
          <div className="mr-2">
            <Input type="select" name="search_condition_type" size="sm" onChange={filterFormDataChange}  >
              <option></option>
              <option value="And">And</option>
              <option value="Or">Or</option>
            </Input>
          </div>
          <div>
              <Button color="primary" size="sm"  onClick={btnSearcFilterClick} >Search</Button>
                <Button color="secondary" size="sm" className="mr-3" onClick={btnResetSearchFilter} >Reset Search</Button>
          </div>
      </div>

    )
  }

export default AweDataTable;