import logo200Image from 'assets/img/logo/logo_200_white.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
class UserPasswordReset extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        password:"",
        username:""
      },
      loading:false,
    }
  } 


  handleSubmit = event => {
    if (this.state.formNewInput.password===""){
      alert("Password is Empty")
    }
    
    else{

      this.props.userUpdatePassword("userUpdatePassword",this.state.formNewInput);
      
    }
  };

  formNewInputChange=(event)=> {

    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    //console.log(this.state.formNewInput)
    //console.log("value"+value)
  }
  onSubmit = () => {
   // alert("ok1")
    this.props.history.push('/');
  };
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'100vh', color:'white' }}>
      <div className="col-md-3"  >
    
      <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'>
        <Form onSubmit={this.handleSubmit}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 140, height: 60, cursor: 'pointer' }}
                alt="logo"
            
              />
              <br/>
            <Label for=""></Label>
            </div>
          )}
            <FormGroup>
              <Label for="">Enter New Password</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="password"
                  id="password"
                  placeholder="Enter New Password"
                  onChange={this.formNewInputChange}
                />
            </FormGroup>
          <hr />
            <Button
            size="sm"
            className="border-0"
            color="primary"
            block
            onClick={this.handleSubmit}>
            Save
          </Button>
          <Button   size="sm" className="border-0" color="link" onClick={()=>this.onSubmit()}> Sign In </Button>
      
          {children}
        </Form>
      </LoadingOverlay>
    
      </div>
      </div>
    );
  }
  componentDidMount() {
    //console.log("did mount")
    let data={
        'product_ID':1
    }
    //alert(JSON.stringify(this.props.location.state))
    this.state.formNewInput.username=this.props.location.state.username
    this.state.formNewInput.otp_sms=this.props.location.state.otp
  }

  componentWillReceiveProps(nextProps) {
     this.setState({loading:false},()=>{console.log("okkkkk")})

    if (nextProps.TYPE==='USER_REGISTRATION'){
      if (nextProps.USER_REGISTRATION.STATUS==="SUCCESS"){
        alert("User Registered Successfully: Username for login is "+nextProps.USER_REGISTRATION.USERNAME)
          nextProps.history.push({ pathname: '/' })
        
       // this.props.addToCart('',this.props.PRODUCT_LIST)
      }else{
        alert("Sorry,User Registered Failed: "+nextProps.USER_REGISTRATION.MSG)
      }
    }
    else if (nextProps.TYPE=='USER_UPDATE_PASSWORD'){
       alert(JSON.stringify(nextProps.USER_UPDATE_PASSWORD.MSG))
      this.props.history.push({pathname: '/',state: { } })
    }

  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserPasswordReset.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserPasswordReset.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducers")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_REGISTRATION: mainReducers.USER_REGISTRATION,
     PRODUCT_LIST:mainReducers.PRODUCT_LIST,
     ADD_TO_CART:mainReducers.ADD_TO_CAR,
     USER_UPDATE_PASSWORD:mainReducers.USER_UPDATE_PASSWORD
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPasswordReset) ;

