import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React,{ useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
import Razorpay from'razorpay'
import PaymentForm from 'components/MyWidget/PaymentForm';

class UserRegistrationPayment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        name:'',
        password:'',
        email:'',
        mobile:'',
      },
      loading:false,
      amount: 100      
    }
    this.changeAmount = this.changeAmount.bind(this);
    this.openCheckout = this.openCheckout.bind(this);
  } 


  handleSubmit = event => {
   //event.preventDefault();
    //alert(event)
    //alert(JSON.stringify(this.state.formNewInput))
    //{"name":"","password":"","email":"","mobile":""}
    if (this.state.formNewInput.name===""){
      alert("Name is Empty")
    }
    else if (this.state.formNewInput.password===""){
      alert("Password is Empty")
    }
    else if (this.state.formNewInput.mobile===""){
      alert("Mobile is Empty")
    }
    else if (this.state.formNewInput.email===""){
      alert("Email  is Empty")

    }else{
     /// this.props.userRegistration("userRegistration",this.state.formNewInput);
      this.setState({loading:true},()=>{console.log("okkkkk")})

    }
  };

  formNewInputChange=(event)=> {

    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    //console.log(this.state.formNewInput)
    //console.log("value"+value)
  }

  changeAmount(e) {
    this.setState({amount: e.target.value})
  }

  openCheckout=()=> {
    let options = {
      "key": "rzp_test_rWnioL1XpzeoHL",
      "amount": this.state.amount, // 2000 paise = INR 20, amount in paisa
      "name": "Merchant Name",
      "description": "Purchase Description",
      "image": "/your_logo.png",
      "handler": function (response){
        alert(response.razorpay_payment_id);
      },
      "prefill": {
        "name": "Harshil Mathur",
        "email": "harshil@razorpay.com"
      },
      "notes": {
        "address": "Hello World"
      },
      "theme": {
        "color": "#F37254"
      }
    };

    let rzp =new window.Razorpay(options)

    rzp.open();
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;
  //  rzp.orders.create({amount:100, currency:"INR", receipt:"20", payment_capture:0, notes:"testing"})

    return (
      <div style={{margin:"5rem", marginLeft:"15rem",marginRight:"15rem",  justifyContent: "center", height:'100vh'}}>
        <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...' >
          <PaymentForm insertRegistrationFeeDetail={(e,a)=>this.props.insertRegistrationFeeDetail(e,a)} onSubmit={(e)=>this.onSubmit(e)}  userDetail={this.props.USER_REGISTRATION} productList={this.props.PRODUCT_LIST}/> 
        </LoadingOverlay>
      </div>
    );
  }
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    console.log("next props")
    //console.log(nextProps)
    if (nextProps.TYPE==='USER_REGISTRATION'){
      if (nextProps.USER_REGISTRATION.STATUS==="SUCCESS"){
        alert("User Registered Successfully")
      }else{
        alert("Sorry,User Registered Failed")

      }
    }else if (nextProps.TYPE==='INSERT_TRANSACTION_REG'){
      if (nextProps.INSERT_TRANSACTION_REG.STATUS==="SUCCESS"){
        alert("Your Transaction Was Successfull")

        nextProps.history.push({ pathname: '/' })
      }else{
        alert(nextProps.INSERT_TRANSACTION_REG.MSG)

      }
    }



      this.setState({loading:false},()=>{console.log("okkkkk")})
  
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserRegistrationPayment.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserRegistrationPayment.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducer")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_REGISTRATION: mainReducers.USER_REGISTRATION,
     PRODUCT_LIST:mainReducers.PRODUCT_LIST,
     INSERT_TRANSACTION_REG:mainReducers.INSERT_TRANSACTION_REG,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistrationPayment) ;

