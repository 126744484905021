import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdCancel as DeleteIcon } from 'react-icons/md'
import styles from './LineItem.module.scss'


class LineItem extends Component {

  render = () => {

    const { index, name, description,  price } = this.props

    return (
      <div className={styles.lineItem} >
        <div>{index + 1}</div>
        <div >
          <span>
         {name} <br/>
         <span style={{color: "grey",fontSize:12}}>
         ({description})
         </span>
         </span>
        </div>
        
        <div className={styles.currency} >{this.props.currencyFormatter(  price )}</div>
      </div>
    )
  }
}

export default LineItem

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


