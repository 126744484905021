import React from 'react';
import PropTypes from 'utils/propTypes';

import classNames from 'classnames';

import { Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';

import Avatar from '../Avatar';
import Typography from 'components/Typography';
const UserCardNew = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = classNames('bg-gradient-theme', className);

  return (
    <Card style={{background: 'linear-gradient(to bottom, #868f96, #596164)'}} >
      <CardBody className="d-flex justify-content-center align-items-center flex-column">
        <CardTitle> <Typography className="mb-0" style={{color:"#fff", fontSize:24}} >
                     <strong>{title}</strong>
                    </Typography></CardTitle>
        <CardSubtitle><Typography className="mb-0" style={{color:"#fff", fontSize:24}} >
                      <strong>{subtitle}</strong>
                    </Typography></CardSubtitle>
        <CardText style={{color:"#fff", fontSize:20}}>
         <Typography> <small><strong>{text}</strong></small></Typography>
        </CardText>
      </CardBody>
      {children}
    </Card>
  );
};

UserCardNew.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCardNew.defaultProps = {
  avatarSize: 80,
};

export default UserCardNew;
