import logo200Image from 'assets/img/logo/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import { MdAccountCircle, MdBrush, MdVisibility, MdVisibilityOff} from 'react-icons/md';
 
import LoadingOverlay from 'react-loading-overlay'
class AuthForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewTask:{
        username:'',
        password:'',
      },
      flag:0,
      loading:false,
      
    }
  } 
 
  get isLogin() { return this.props.authState === STATE_LOGIN; }
  get isSignup() { return this.props.authState === STATE_SIGNUP; }

  changeAuthState = authState => event => {
    //event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
   //event.preventDefault();
    //alert(event)
    //alert(JSON.stringify(this.state.formNewTask))
   
    this.props.loginCheck("loginCheck",this.state.formNewTask);
    //this.props.loginCheck("test1",this.state.formNewTask);
     this.setState({loading:true},()=>{console.log("okkkkk")})
  };



  renderButtonText() {
    const { buttonText } = this.props;
    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }
    return buttonText;
  }

  formNewTaskInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewTask
    formTask[key]=value;
    this.setState({formTask})
    console.log(this.state.formNewTask)
    console.log("value"+value)
  }
  
  onIconClick=()=>{
    if (this.state.flag==0){
      this.setState({flag:1},()=>{})
      alert(this.state.formNewTask.password)
    }else{
      this.setState({flag:0},()=>{})
      alert(this.state.formNewTask.password)
    }
  }

  onKeyPress=(e)=> {
    if (e.key === 'Enter') {
     this.handleSubmit()
    }
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;
    let inputType=this.state.flag==0?"password":"text"
    let loginButton;
    if (this.state.flag==0) {
      loginButton = <MdVisibility className={'nav-item-icon'}  style={{padding: 0}}/>;
    } else {
      //loginButton = <MdVisibilityOff className={'nav-item-icon'}  style={{padding: 0}} />;
      loginButton = <MdVisibility className={'nav-item-icon'}  style={{padding: 0}}/>;
    }
    return (
          <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading your content...'
              >
      <Form onSubmit={this.handleSubmit} autoComplete="new-password"  autocomplete="new-password">
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 250, height: 50, cursor: 'pointer' }}
              alt=""
             
            /><br/>
         
          </div>
        )}
          <FormGroup>
            <Label for="username">User Name</Label>
              <Input
                bsSize="sm"
                type="text"
                name="username"
                id="username"
                placeholder="Enter User Name"
                onChange={this.formNewTaskInputChange}
                autoComplete="new-password"
                autocomplete="new-password"
              />
          </FormGroup>
          
          <FormGroup>
            <Label for="password">Password</Label>
             <InputGroup size="sm">
              <Input
                bsSize="sm"
                type="password"
                name="password"
                id="password"
                placeholder="Enter Password"
                onChange={this.formNewTaskInputChange}
                onKeyDown={this.onKeyPress}
                autoComplete="new-password"
                autocomplete="new-password"
               
              />
              <InputGroupAddon addonType="append" size="sm">
                <InputGroupText size="sm">
                  <i   onClick={()=>this.onIconClick()}>
                  {loginButton}
                    
                  </i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
       
        <hr />
        <Button
          size="lg"
          className="border-0"
          color="warning"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
        </Button>
     

        {children}
      </Form>
      </LoadingOverlay>
    );
  }
    componentDidMount() {
       this.props.logoutCheck("logout",'');
  }

  componentWillReceiveProps(nextProps) {
    //console.log("next props")
    //console.log(nextProps)
    this.setState({loading:false},()=>{console.log("okkkkk")})
  
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

function mapStateToProps({ mainReducers}) {
 
  //console.log("First auth form  main reducer ")
  //console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     LOGIN_CHECK: mainReducers.LOGIN_CHECK,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthForm) ;

