import logo200Image from 'assets/img/logo/logo_200_white.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdOpenInNew,
  MdMenu,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
 // Redux Import FIle Start
 import { connect } from "react-redux";
 import { ActionCreators } from "actions";
 import { bindActionCreators } from "redux";
 import * as ActionTypes from "actions/ActionTypes";
 // Redux Import File Ends

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const mainMenuOld = [
  { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/admin-new-task', name: 'Add New Task', exact: false, Icon: MdWidgets },
  { to: '/admin-view-task', name: 'View Assign Task', exact: false, Icon: MdWidgets },
  { to: '/admin-add-company', name: 'Add Company', exact: false, Icon: MdWidgets },
  { to: '/admin-add-division', name: 'Add Division', exact: false, Icon: MdWidgets },
  { to: '/admin-add-department', name: 'Add Department', exact: false, Icon: MdWidgets },
  { to: '/admin-add-designation', name: 'Add Designation', exact: false, Icon: MdWidgets },
  { to: '/employee-registration', name: 'Add Employee', exact: false, Icon: MdWidgets },
  { to: '/admin-view-employee', name: 'View Employee', exact: false, Icon: MdWidgets },
  { to: '/test-page', name: 'Test Page', exact: false, Icon: MdWidgets },

];

const navItems = [
  { to: '/dashboard', name: 'DASHBOARD', exact: false, Icon: MdDashboard },
];

const navItemLogout = [
  { to: '/', name: 'LOGOUT', exact: true, Icon: MdDashboard },
];


const bem = bn.create('sidebar');
const components = {
    MdDashboard: MdDashboard,
    video: MdWidgets
};
class Sidebar extends React.Component {
   constructor (props) {
    super(props)
    this.state = {
      isOpenComponents: false,
      isOpenContents: false,
      isOpenPages: false,
      isOpenAdminCompnay:false,
      mainMenu:{},
      dashboard:{}
    }
  } 
  
  handelOpenCloseMenu=(index)=>{
    const Menu = this.state.mainMenu
    Menu[index].isOpen=Menu[index].isOpen=="false"?"true":"false"
    this.setState(this.state.mainMenu)
    // alert(Menu[index].isOpen)
    this.state.mainMenu[index].isOpenComponents=! this.state.mainMenu[index].isOpenComponents

  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
     const dashboard=JSON.stringify(this.state.dashboard)==="{}"?navItems:this.state.dashboard
    return (
      /* <aside className={bem.b()} data-image={sidebarBgImage}>*/ 
      <aside className={bem.b()} >
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="160"
                height="60"
                className="pr-2"
                alt="#"
              />
             
            </SourceLink>
          </Navbar>

    {/************************************************************************** */}
    <Nav vertical>
    {dashboard.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact} 
                    style={{color:"#fff", fontSize:14 }}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="" style={{color:"#fff", fontSize:14 }}>{name}</span>
                  </BSNavLink>
                </NavItem>
        ))
    }
    {/******************************************************************* */}
    {JSON.stringify(this.state.mainMenu)!="{}"?this.state.mainMenu.map(({ name, Icon , isOpen, menuItems,isOpenComponents}, index) => (
    <div>
      <NavItem
        className={bem.e('nav-item')}
        onClick={()=>this.handelOpenCloseMenu(index)}
        >
            <BSNavLink className={bem.e('nav-item-collapse')}>
              <div className="d-flex">
                <MdMenu className={bem.e('nav-item-icon')} />
                <span className=" align-self-start">{name}</span>
              </div>
              <MdKeyboardArrowDown
                className={bem.e('nav-item-icon')}
                style={{
                  padding: 0,
                  transform: isOpenComponents
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)',
                  transitionDuration: '0.3s',
                  transitionProperty: 'transform',
                }}
              />
            </BSNavLink>
      </NavItem>
      
      <Collapse isOpen={isOpen=="false"?false:true}>
        {menuItems.map(({ to, name, exact, Icon }, index) => (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                //className="text-uppercase"
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <span className="" style={{color:"#fff", marginLeft: '2rem', fontSize:13 }}> {name}</span>
              </BSNavLink>
            </NavItem>
          ))}
      </Collapse>

      </div>
        )):
      <div>

      </div>
      } 

     
         {navItemLogout.map(({ to, name, exact, Icon }, index) => (
          <NavItem key={index} className={bem.e('nav-item')}>
            <BSNavLink
              id={`navItem-${name}-${index}`}
              //className="text-uppercase"
              tag={NavLink}
              to={to}
              activeClassName="active"
              exact={exact} 
              style={{color:"#fff", fontSize:14 }}
            >
              <Icon className={bem.e('nav-item-icon')} />
              <span className="" style={{color:"#fff", fontSize:14 }}>{name}</span>
            </BSNavLink>
          </NavItem>
        ))}


         </Nav>
        </div>
            
      </aside>
    );
  }

  componentDidMount() {
    if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
        let data={
        'userID':this.props.LOGIN_CHECK.user_ID,
      }
      this.props.viewSidebarMenu("userSideMenu",data);
    }else{
      //  this.props.history.push({pathname: '/' })
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps)
    if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID==0){
       // nextProps.history.push({      pathname: '/'       })
    }else if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID!=0){
        nextProps.history.push({
        pathname: '/'
        })
    }else{
       if (nextProps.TYPE=="SIDEBAR_MENU"){
          this.setState((state) => {
          return {
          mainMenu:nextProps.SIDEBAR_MENU.menu ,
          dashboard: nextProps.SIDEBAR_MENU.dashboard
          };
          },()=>{});
        }
    }
  }
  //Class Ends Here
}


function mapStateToProps({ mainReducers}) {
 
   return {
     TYPE:mainReducers.TYPE,
     SIDEBAR_MENU:mainReducers.SIDEBAR_MENU,
     LOGIN_CHECK:mainReducers.LOGIN_CHECK,
     history:mainReducers.history,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar) ;

